import * as React from 'react';
//import numberWithCommas from '../../../../components/elements/numberCommas'
import convertExponentialToDecimal from '../../../../helpers/decimals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  index: number;
  img: string;
  symbol: string;
  name: string;
  price: any;
  percent_change_24h: any;
  metadata: any;
  trending: any;
  id: any;
  mostVisited: any;
  gainersLosers: any;
  percent_change_7d: any;
  marketCap: any;
  volume_24h: any;
  circulating_supply: any;
  max_supply: any;
  total_supply: any;
  fully_diluted_market_cap: any;
  market_cap_dominance: any;
  rank: any;
}

const SpotLightTable = ({ id, index, img, name, price, percent_change_24h, metadata, trending, mostVisited, gainersLosers, percent_change_7d,
    marketCap, volume_24h, circulating_supply, max_supply, total_supply, fully_diluted_market_cap, market_cap_dominance, rank, symbol } : Props) => {

    const coinPage = "/currencies/";
    const urlName = name.replace(/\s/g, '-');

    return (
        <tr style={{borderBottom: "1px solid rgb(239, 242, 245)", padding: '8px', display: 'flex', width: '420px', alignItems: 'center'}}>
            <td style={{color: 'rgb(88, 102, 126)', width: '40px', fontWeight: 500}}>{index + 1}</td>
            <td style={{marginRight: '5px'}}><img style={{width: '25px'}} src={img} /></td> 
            <td style={{width: '150px', fontWeight: 600,fontSize: '14px'}}>
                <div>
                    <Link to={{
                        pathname: coinPage + urlName.toLowerCase(),
                        state: {
                            // cryptoIndex,
                            rank,
                            fully_diluted_market_cap,
                            market_cap_dominance,
                            metadata,
                            trending,
                            mostVisited,
                            gainersLosers,
                            id,
                            symbol,
                            name,
                            price,
                            percent_change_24h,
                            percent_change_7d,
                            marketCap,
                            volume_24h,
                            circulating_supply,
                            max_supply,
                            total_supply,
                        },
                    }}>
                        <span>{name}</span>
                    </Link>
                </div>
            </td>
            <td style={{width: '150px',textAlign: 'right', fontWeight: 500}}>${convertExponentialToDecimal(price)}</td>
            <td style={{color: percent_change_24h > 0 ? "#16c784" : "#ea3943", textAlign: 'right', width: '120px', fontWeight: 700 }}>
                {percent_change_24h > 0 ? (
                    <FontAwesomeIcon icon={faCaretUp  as IconProp} />
                ) : (
                    <FontAwesomeIcon icon={faCaretDown  as IconProp} />
                )}
                {Math.abs(percent_change_24h.toFixed(2))}%
            </td>
        </tr>
    )
}

export default SpotLightTable;