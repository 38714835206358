import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const CoinAds = () => {
  return (
    <div>
      <button>Buy <FontAwesomeIcon icon={faCaretDown as IconProp} /></button>
      <button>Exchange <FontAwesomeIcon icon={faCaretDown as IconProp} /></button>
      <button>Gaming <FontAwesomeIcon icon={faCaretDown as IconProp} /></button>
      <button>Earn Crypto <FontAwesomeIcon icon={faCaretDown as IconProp} /></button>
    </div>
  );
};

export default CoinAds;
