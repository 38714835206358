import React from "react";
import { useLocation } from "react-router";
import { useState, useCallback, useEffect } from "react";
import { CryptoCalls } from "../../api/api";
import Overview from "./components/Overview";
import CoinDetails from "./components/CoinDetails";
import HistoricalData from "./components/HistoricalData";
import CoinPageNav from './components/CoinPageNav';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from '../../components/custom-hooks/scrollToTop';
import Cookies from 'js-cookie';
import { useRouter } from '../../components/custom-hooks/useRouter';
import CoinAds from './components/CoinAds';
import arrayMap from "../../helpers/arrayMap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const CoinPage = () => {
  const location: any = useLocation();
  const router: any = useRouter();

  //state
  const [name, setName] = useState<string>()
  const [id, setId] = useState(Number)
  const [rank, setRank] = useState();
  const [symbol, setSymbol] = useState();
  const [price, setPrice] = useState();
  const [percent_change_24h, set_percent_change_24h] = useState();
  const [percent_change_7d, set_percent_change_7d] = useState();
  const [fully_diluted_market_cap, set_fully_diluted_market_cap] = useState();
  const [marketCap, setMarketCap] = useState();
  const [volume_24h, setVolume_24h] = useState();
  const [circulating_supply, set_circulating_supply] = useState();
  const [max_supply, setMax_supply] = useState();
  const [total_supply, setTotal_supply] = useState();
  const [isInWatchList, setIsInWatchList] = useState();
  const [clicked, setClicked] = useState<boolean>(false);
  const [activeId, setActiveId] = useState(1);
  const [metadata, setMetadata] = useState();
  const [stats, setStats] = useState(Object);
  // const [description, setDescription] = useState(String);
  const [loading, setLoading] = useState<boolean>();
  const [finished, setFinished] = useState<boolean>(false);
  const [cookie] = useState(Cookies.get('cookie'));

  const [OverviewProps, setOverviewProps] = useState({ coinFromUrl: false, location: undefined, description: '', isInWatchList: [] });

  const [CoinPageNavProps, setCoinPageNavProps] = useState({ name, id, activeId, setActiveId: setActiveId })

  //const [coinData, setCoinData] = useState({ type: "coin-quotes", coinName: "" });
  const [coinDescriptionData, setCoinDescriptionData] = useState({ coinId: 1, coinName: "bitcoin" });
  const [watchListInfo, setWatchListInfo] = useState({ userEmail: Cookies.get('email'), coinId: id });
  const [valueWatchlist] = useState({ userEmail: Cookies.get("email") });
  const [valuesStats, setValuesStats] = useState({ type: "stats", id: "" })

  const [logoIds, setLogoIds] = useState(Array);

  //Trending data
  const [trending, setTrending] = useState(Object);
  const [mostVisited, setMostVisited] = useState(Object);
  const [gainersLosers, setGainersLosers] = useState(Object);

  const [trendingIds, setTrendingIds] = useState(Array);
  const [mostVisitedIds, setMostVisitedIds] = useState(Array);
  const [biggestGainersIds, setBiggestGainersIds] = useState(Array);
  const [allData, setAllData] = useState(Array);
  const [allCoinDatabase, setAllCoinDatabase] = useState(true);
  const [locationState, setLocationState] = useState();

  const [whitepaper, setWhitepaper] = useState();
  const [coinDatabase, setCoinDatabase] = useState(true);
  const [chat, setChat] = useState();
  const [github, setGithub] = useState();
  const [twitter, setTwitter] = useState();
  const [explorers, setExplorers] = useState();
  const [reddit, setReddit] = useState();
  const [url, setUrl] = useState();
  const [img, setImg] = useState();
  const [category, setCategory] = useState();
  const [tags, setTags] = useState();
  const [description, setDescription] = useState();
  const [metaId, setMetaId] = useState();

  const getWatchList = useCallback(async () => {
    if (valueWatchlist.userEmail) {
      await CryptoCalls.getWatchListCoinIdByUser(valueWatchlist).then((info: any) => {
        let data = info.data.map((obj: any) => obj.coinId);
        setIsInWatchList(data);
      });
    }
  }, [valueWatchlist]);

  const getFetchTrending = async () => {
    CryptoCalls.getTrendingDatabase({ type: "trending" }).then((data: any) => {
      setTrending(data.data);
      setTrendingIds(arrayMap(data.data));
    });
  }

  const getFetchMostVisited = async () => {
    CryptoCalls.getMostVisitedDatabase({ type: "trending_most_visited" }).then((data: any) => {
      setMostVisited(data.data);
      setMostVisitedIds(arrayMap(data.data));
    });
  }

  const getFetchBiggestGainers = async () => {
    CryptoCalls.getBigestGainerDatabase({ type: "trending_gainers_losers" }).then((data: any) => {
      setGainersLosers(data.data);
      setBiggestGainersIds(arrayMap(data.data));
    });
  }

  useEffect(() => {
    getFetchTrending()
    getFetchMostVisited()
    getFetchBiggestGainers()
  }, [])

  useEffect(() => {
    if (location.state === undefined) {
      getFetchCoinData(router.query.name);
      getWatchList();
      getFetchTrending();
      getFetchMostVisited();
      getFetchBiggestGainers();
    }
  }, [location, router.query.name])

  //new function
  const getFetchCoinData = async (coinName: string) => {
    CryptoCalls.getCoinByName({ coin: coinName }).then((info: any) => {
      const result = info.info
      if (result) {
        setId(result.id)
        setName(result.name)
        setRank(result.cmcRank)
        setSymbol(result.symbol)
        setMax_supply(result.maxSupply)
        setTotal_supply(result.totalSupply)
        set_circulating_supply(result.circulatingSupply != undefined ? result.circulatingSupply : 0)
        set_fully_diluted_market_cap(JSON.parse(result.quote).USD.fully_diluted_market_cap)
        set_percent_change_24h(JSON.parse(result.quote).USD.percent_change_24h)
        set_percent_change_7d(JSON.parse(result.quote).USD.percent_change_7d)
        setVolume_24h(JSON.parse(result.quote).USD.volume_24h)
        setMarketCap(JSON.parse(result.quote).USD.market_cap)
        setPrice(JSON.parse(result.quote).USD.price)
        setAllData(result.data)
        setWhitepaper(result.whitepaper)
        setChat(result.chat.substring(1, result.chat.length - 1).replace(/["]+/g, '').split(','))
        setGithub(result.github.split(',')[0])
        setTwitter(result.twitter.substring(1, result.twitter.length - 1).replace(/["]+/g, '').split(','))
        setExplorers(result.explorers.substring(1, result.explorers.length - 1).replace(/["]+/g, '').split(','))
        setReddit(result.reddit.substring(1, result.reddit.length - 1).replace(/["]+/g, '').split(','))
        setUrl(result.website.substring(1, result.website.length - 1).replace(/["]+/g, '').split(','))
        setImg(result.logo)
        setCategory(result.category)
        setTags(result.tags)
        setDescription(result.descriptionCrypto)
        setMetaId(result.id)
        setCoinDatabase(false)
        setLocationState(info.info)
      }
    });
  }

  const getCoinByName = async () => {
    await CryptoCalls.getCoinByName({ coin: location.state.name }).then((info: any) => {
      const result = info.info;
      setWhitepaper(result.whitepaper)
      setChat(result.chat.substring(1, result.chat.length - 1).replace(/["]+/g, '').split(','))
      setGithub(result.github.split(',')[0])
      setTwitter(result.twitter.substring(1, result.twitter.length - 1).replace(/["]+/g, '').split(','))
      setExplorers(result.explorers.substring(1, result.explorers.length - 1).replace(/["]+/g, '').split(','))
      setReddit(result.reddit.substring(1, result.reddit.length - 1).replace(/["]+/g, '').split(','))
      setUrl(result.website.substring(1, result.website.length - 1).replace(/["]+/g, '').split(','))
      setImg(result.logo)
      setCategory(result.category)
      setTags(result.tags)
      setDescription(result.descriptionCrypto)
      setMetaId(result.id)
      setCoinDatabase(false)
      setLocationState(info.info)
    });
  }

  useEffect(() => {
    if (location.state) {
      getCoinByName()
    }
  }, [coinDatabase])

  const getAllCoinDatabase = async () => {
    if(allCoinDatabase) {
      const coinIds = ''
      await CryptoCalls.getAllCoinData({ coinIds }).then((info: any) => {
        setAllData(info.data);
        setLoading(false);
        setAllCoinDatabase(false);
      })
    }
  }

  useEffect(() => {
    getAllCoinDatabase()
  }, [allCoinDatabase])

  useEffect(() => {
    if (id) {
      setValuesStats((prevState: any) => {
        return {
          ...prevState,
          id: id,
        };
      });
    } if (location.state === undefined && id && trendingIds.length & biggestGainersIds.length & mostVisitedIds.length) {
      const combine = trendingIds.concat(biggestGainersIds, mostVisitedIds, id);
      const removeDuplicates = [...Array.from(new Set(combine))];
      setLogoIds(removeDuplicates);
    }
  }, [location.state, id, trendingIds, biggestGainersIds, mostVisitedIds])

  useEffect(() => {
    if (location.state === undefined && !loading) {
      setOverviewProps((prevState: any) => {
        return {
          ...prevState,
          coinFromUrl: true,
          location: {
            id: id, name: name, rank: rank, symbol: symbol, price: price, marketCap: marketCap,
            max_supply: max_supply, total_supply: total_supply, circulating_supply: circulating_supply,
            fully_diluted_market_cap: fully_diluted_market_cap, percent_change_24h: percent_change_24h,
            percent_change_7d: percent_change_7d, volume_24h: volume_24h, stats: stats, metadata: metadata,
            trending: trending, mostVisited: mostVisited, gainersLosers: gainersLosers
          },
          description: description,
          isInWatchList: isInWatchList
        }
      })
      setCoinPageNavProps({ name: name, id: id, activeId: activeId, setActiveId: setActiveId })
    }
    setFinished(true);
  }, [circulating_supply, description, fully_diluted_market_cap, id, isInWatchList, loading, location.state, marketCap,
    max_supply, metadata, name, percent_change_24h, percent_change_7d, price, rank, stats, symbol, total_supply, volume_24h])


  useEffect(() => {
    if (location.state && location.state !== undefined && stats) {
      setId(location.state.id)
      setName(location.state.name)
      setRank(location.state.rank)
      setSymbol(location.state.symbol)
      setMax_supply(location.state.max_supply)
      setTotal_supply(location.state.total_supply)
      set_circulating_supply(location.state.circulating_supply)
      set_fully_diluted_market_cap(location.state.fully_diluted_market_cap)
      set_percent_change_24h(location.state.percent_change_24h)
      set_percent_change_7d(location.state.percent_change_7d)
      setIsInWatchList(location.state.isInWatchList)
      setVolume_24h(location.state.volume_24h)
      setMarketCap(location.state.marketCap)
      setPrice(location.state.price)
      // setMetadata(location.state.metadata)
      setAllData(location.state.allData)
      setOverviewProps((prevState: any) => {
        return {
          ...prevState,
          coinFromUrl: false,
          location: { ...location.state, stats: stats },
          description: description,
          isInWatchList: isInWatchList
        }
      })
      setCoinPageNavProps({ name: name, id: id, activeId: activeId, setActiveId: setActiveId })
      setFinished(true);
    } else {
    }
  }, [stats, location, description, loading, isInWatchList])

  const handleWatchList = useCallback(async () => {
    if (!cookie) {
      alert('Please login to use the watchlist!')
    } else {
      if (clicked) {
        setClicked(false);
      } else if (!clicked) {
        setClicked(true);
      }
      await CryptoCalls.addOrRemoveToWatchlist(watchListInfo).then((info: any) => {
      });
    }
  }, [watchListInfo, clicked])

  const getFetchMetadata = useCallback(async () => {
    if (location.state === undefined && logoIds) {
      const coinIds = logoIds;
      await CryptoCalls.getAllCoinData({ coinIds }).then((info: any) => {
        setMetadata((prevState: any) => {
          return {
            ...prevState, ...info.data
          }
        })
      });
    }
  }, [location.state, logoIds])

  const FetchStats = useCallback(async () => {
    if (valuesStats.id) {
      setLoading(true);
      await CryptoCalls.getStats(valuesStats).then((info: any) => {
        setStats((prevState: any) => {
          return {
            ...prevState, ...info.data.data.data
          }
        })
        if (stats !== undefined) {
          setLoading(false);
        }
      });
    }
  }, [valuesStats])

  // useEffect(() => {
  //   getFetchCoinData();
  // }, [getFetchCoinData])

  useEffect(() => {
    getFetchMetadata();
  }, [getFetchMetadata])

  useEffect(() => {
    FetchStats();
  }, [FetchStats])

  // useEffect(() => {
  //   async function fetchDescription() {
  //     let getCoinDescription = await CryptoCalls.getCoinDescription(coinDescriptionData)
  //       .then((info: any) => {
  //         setDescription(info.data);
  //       })
  //   }
  //   fetchDescription();
  // }, [coinDescriptionData, name]);

  const components = [
    { name: "Overview", id: 1, component: <Overview props={finished && OverviewProps} /> },
    { name: "Historical Data", id: 3, component: <HistoricalData props={finished && { name, id }} /> },
  ];

  const showComponents = components.map(component => {
    if (component.id === activeId) {
      return <div className="coin-section-holder">{component.component}</div>
    }
  })

  return (
    <div>
      {!coinDatabase &&
        // const metaId = line.id;
        // if (id === metaId) {
        // const { logo: img, category, tags, description } = line;
        // const url = line.website.substring(1, line.website.length - 1).replace(/["]+/g, '').split(',');
        // const github = line.github.split(',')[0];
        // const whitepaper = line.whitepaper.split(',')[0];
        // const twitter = line.twitter.substring(1, line.twitter.length - 1).replace(/["]+/g, '').split(',');
        // const explorers = line.explorers.substring(1, line.explorers.length - 1).replace(/["]+/g, '').split(',');
        // const reddit = line.reddit.substring(1, line.reddit.length - 1).replace(/["]+/g, '').split(',');
        // const chat = line.chat.substring(1, line.chat.length - 1).replace(/["]+/g, '').split(',');
        <>
          <ScrollToTop />
          <div key={metaId} className="coin-banner">
            <div>
              <div className="breadcrumbs">
                <Link to='/'>Cryptocurrencies</Link><FontAwesomeIcon icon={faChevronRight as IconProp} />
                <span>{name}</span></div>
              <div className="first">
                <div>
                  {/*watchlists brojot treba da se vidi od kade da go generira */}
                  <span>Rank #{rank}</span>
                  {/* <span className="category">{category}</span> */}
                  <span>On 1,796,056 watchlists</span>
                </div>
                <CoinAds />
              </div>
              <CoinDetails
                props={{
                  rank,
                  id,
                  name,
                  symbol,
                  price,
                  percent_change_24h,
                  percent_change_7d,
                  fully_diluted_market_cap,
                  marketCap,
                  volume_24h,
                  circulating_supply,
                  max_supply,
                  total_supply,
                  stats,
                  img,
                  category,
                  url,
                  explorers,
                  tags,
                  twitter,
                  reddit,
                  github,
                  whitepaper,
                  chat,
                  description,
                  handleWatchList,
                  setClicked,
                  clicked,
                  isInWatchList
                }}
              />
            </div>
          </div>
        </>
        // }
      }
      <CoinPageNav props={finished && CoinPageNavProps} />
      {showComponents}
    </div>
  );
};

export default CoinPage;
