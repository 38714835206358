import * as React from "react";
import numberWithCommas from "../../../helpers/numberCommas";
import { SupplySlider } from "../slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faInfinity } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const CoinStatsInfo = (CoinStatsProps: any) => {
  const {
    marketCap,
    volume_24h,
    fully_diluted_market_cap,
    circulating_supply,
    max_supply,
    symbol,
    total_supply,
  } = CoinStatsProps;

  const circulating_percent = Math.round((circulating_supply * 100) / max_supply);

  const renderCirculatingPercent = () => {
    switch (circulating_percent) {
      case 0:
        return null;
        //break;
      case Infinity:
        return <FontAwesomeIcon icon={faInfinity as IconProp} />
        //break;
      default:
        if (isNaN(circulating_percent)){
          return null;
          //break;
       }
        return <p>{circulating_percent + '%'}</p>
    }
  }

  return (
    <div className="stats-info">
      <div>
        <p className="title">
          <span>Market Cap</span>
          <FontAwesomeIcon icon={faInfoCircle as IconProp} />
        </p>
        <p className="value">
          {marketCap === 0 ? ('--') : (<span>${numberWithCommas(marketCap.toFixed(0))}</span>)}
          <span>no.</span>
        </p>
      </div>
      <div>
        <p className="title">
          <span>Volume 24</span>
          <FontAwesomeIcon icon={faInfoCircle as IconProp} />
        </p>
        <p className="value">
          <span>${numberWithCommas(volume_24h.toFixed(0))}</span>
          <span>no.</span>
        </p>
      </div>
      <div>
        <p className="title">
          <span>Fully Diluted Market Cap</span>
          <FontAwesomeIcon icon={faInfoCircle as IconProp} />
        </p>
        <p className="value">
          {fully_diluted_market_cap === 0 ? ('--') : (<span>${numberWithCommas(fully_diluted_market_cap.toFixed(0))}</span>)}
          <span>no.</span>
        </p>
      </div>
      <div>
        <p className="title">
          <span>Circulating supply</span>
          <FontAwesomeIcon icon={faInfoCircle as IconProp} />
        </p>
        <div>
          {circulating_supply === 0 ? (
            <span>--</span>
          ) : (
            <p>{circulating_supply === 0 ? ('--') : numberWithCommas(circulating_supply.toFixed(0))} {symbol}</p>
          )}
          {renderCirculatingPercent()}
        </div>
        {circulating_supply !== 0 ? ( 
        <SupplySlider
          props={{
            max_supply,
            circulating_supply,
          }}
        />
        ) : (
          null
        )}
        <p className="max">
          <span>Max supply</span>
          <span>
            <FontAwesomeIcon icon={faInfoCircle as IconProp} />
          </span>
          {max_supply === null ? (
            <span>--</span>
          ) : (
            <span>{numberWithCommas(Number(max_supply).toFixed(0))}</span>
          )}
        </p>
        <p className="total">
          <span>Total supply</span>
          <span>
            <FontAwesomeIcon icon={faInfoCircle as IconProp} />
          </span>
          {total_supply === 0 ? (<span>--</span>) : ( <span>{numberWithCommas(Number(total_supply).toFixed(0))}</span>)}
        </p>
      </div>
    </div>
  );
};

export default CoinStatsInfo;
