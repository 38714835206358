import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';

const Categories = () => {
    const [value] = useState({type: 'categories'});

    //ethereum ecosystem = 618c0beeb7dd913155b462f9
    //Solana Ecosystem = 60521ff1df5d3f36b84fbb61
    //Avalanche Ecosystem = 6051bc098a9b3f285eec4d40
    //BSC Ecosystem = 60308028d2088f200c58a005
    //Polkadot Ecosystem = 601cf8d2d8fd860e4ea5d96f

    useEffect(() => {
        axios.post('/home', value)
            .then((data) => {
                console.log(data, 'data')
            })
            .catch((err) => {
                console.log(err.response, 'errr')
            });
    }, [])

    return (
        <>
        </>
    )
}

export default Categories;