import React from 'react';


export default function Button({ label, type, handleClick } : { label:string, type: string, handleClick:React.MouseEventHandler<HTMLElement>}) {
    return (
      <button onClick={handleClick}>
        {label}
      </button>
    );
}
