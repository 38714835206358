import numberWithCommas from "./numberCommas";

export function shortNumber(number) {
    const str = number.toString();
    let regExp = /^0[0.].*$/
    if(regExp.test(str) == true) {
        return str
    }
    else if (Number(str) > 0.98 && Number(str) < 1.02) {
        return str
    }
    else {
        return Number(str).toFixed(2)
    }
}

export default function convertExponentialToDecimal(exponentialNumber) {
    const str = exponentialNumber.toString();
    if (str.indexOf('e') !== -1) { //returns -1 if the value is not found.
        const exponent = parseInt(str.split('-')[1]);
        const result = exponentialNumber.toFixed(exponent + 2);
        return result;
    } if (str.indexOf('e') == -1) {
        const isThousand = Math.floor(Math.log10(exponentialNumber)) + 1;
        if (isThousand === 5) {
            const result = numberWithCommas(exponentialNumber.toFixed(2));
            return result;
        } if (isThousand === 4) {
            const result = numberWithCommas(exponentialNumber.toFixed(2));
            return result;
        } if (isThousand === 3) {
            const result = numberWithCommas(exponentialNumber.toFixed(2));
            return result;
        }
        const result = Number(str).toFixed(2).match(/^-?\d*\.?0*\d{0,2}/)[0];
        return result;
        //const number = exponentialNumber.toFixed(Math.log10(exponentialNumber) < 0 ? 4 : 2); 
        //return number;
    } else {
        // const newResult = numberWithCommas(exponentialNumber.toFixed(2));
        // return newResult;
    }
}

