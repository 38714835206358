import React from "react";
import Logo from "../../assets/Images/logo.svg";
import Facebook from "../../assets/Images/Icon awesome-facebook.svg";
import Twitter from "../../assets/Images/Icon awesome-twitter.svg";
import Telegram from "../../assets/Images/Icon awesome-telegram.svg";
import Instagram from "../../assets/Images/Icon awesome-instagram.svg";
import Chat from "../../assets/Images/Icon material-chat.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div>
          <Link to="/home"><img src={Logo} alt="Logo" /></Link>
        </div>
        {/* <div>
          <p className="title">Products</p>
          <ul>
            <li><Link to="#">Blockchain Explorer</Link></li>
            <li><Link to="#">Crypto Api</Link></li>
            <li><Link to="#">Crypto Indices</Link></li>
            <li><Link to="#">Interest</Link></li>
            <li><Link to="#">Jobs Board</Link></li>
            <li><Link to="#">Sitemap</Link></li>
          </ul>
        </div> */}
        <div>
          <p className="title">Company</p>
          <ul>
            <li><Link to="/about">About us</Link></li>
            <li><Link to="/terms-and-conditions">Terms of use</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="#">Request Form</Link></li>
            <li><Link to="/contact">Contact Support</Link></li>
            <li><Link to="/faq">Faq</Link></li>

            {/* <li><Link to="#">Disclaimer</Link></li> */}
            {/* <li><Link to="#">Methodology</Link></li> */}
            {/* <li><Link to="#">Careers</Link></li> */}
          </ul>
        </div>
        {/* <div>
          <p className="title">Support</p>
          <ul>
            <li><Link to="#">Request Form</Link></li>
            <li><Link to="/contact">Contact Support</Link></li>
            <li><Link to="/faq">Faq</Link></li>
            <li><Link to="#">Glossary</Link></li>
          </ul>
        </div> */}
        <div>
          <p className="title">Socials</p>
          <ul>
            <li><Link to="#"><span>Facebook</span><img src={Facebook} alt="facebook" /></Link>
            </li>
            <li><Link to="#"><span>Twitter</span><img src={Twitter} alt="twitter" /></Link>
            </li>
            <li><Link to="#"><span>Telegram</span><img src={Telegram} alt="telegram" /></Link>
            </li>
            <li><Link to="#"><span>Instagram</span><img src={Instagram} alt="instagram" /></Link>
            </li>
            <li>
              <Link to="#"><span>Interactive Chat</span><img src={Chat} alt="chat" /></Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottomBar">
        <p>© 2021 CoinIMA. All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
