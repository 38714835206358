import React from 'react';
import { Navbar as BsNavbar, NavItem, Nav, NavbarBrand, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import Logo from "../../assets/Images/logo.svg";
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Logout from '../../pages/logout/Logout';
import numberWithCommas from '../../helpers/numberCommas';
// @ts-ignore 
import Cookies from 'js-cookie';

const Header = (props: any) => {
  const { totalCurrencies, activeExchanges, marketCap, volume24, btcDominance, ethDominance } = props.props;

  const [email, setEmail] = useState('');
  const [cookie, setCookie] = useState(false);
  const [admin, setAdmin] = useState(Boolean);

  useEffect(() => {
    const cookie = Cookies.get('cookie')
    const email = Cookies.get('email')
    const admin = Cookies.get('admin')
    if (cookie && email) {
      setCookie(true);
      setEmail(email);
      if (admin === '1') {
        setAdmin(true);
      }
    }
  }, [cookie, email]);

  return (
    <>
      <div className="topBar">
        <div className="column1">
          <p><span>Cryptos:</span><span>{numberWithCommas(Number(totalCurrencies))}</span></p>
          <p><span>Exchanges:</span><span>{activeExchanges}</span></p>
          <p><span>Market Cap:</span><span>${numberWithCommas(Number(marketCap).toFixed(0))}</span></p>
          <p><span>24h Vol:</span><span>${numberWithCommas(Number(volume24).toFixed(0))}</span></p>
          <p><span>Dominance:</span><span>BTC:</span><span>{numberWithCommas(Number(btcDominance).toFixed(1))}%</span>
            <span>ETH:</span><span>{numberWithCommas(Number(ethDominance).toFixed(1))}%</span></p>
        </div>
        <div className="column2">
          <p>English</p>
          <p>USD</p>
        </div>
      </div>
      <BsNavbar collapseOnSelect expand="xl" className="custom-nav">
        <Container fluid>
          <NavbarBrand className="">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
          </NavbarBrand>
          <NavbarToggle aria-controls="responsive-navbar-nav" />
          <NavbarCollapse id="responsive-navbar-nav" className="main-menu">
            <Nav className="custom-menu">
              <NavItem>
                <NavLink to="/portfolio" className="">Portfolio</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/blog" className="">Blog</NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/about" className="">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/contact" className="">Contact</NavLink>
              </NavItem>
              <NavItem>
                <div className="authLinks" >
                  <>
                    {cookie ?
                      (
                        <div className="log-auth">
                          <p>{email}</p>
                          <div>
                            <Logout />
                            {admin && <a href="/admin-panel">Admin Panel</a>}
                          </div>
                        </div>
                      ) : (

                        <div className="main-auth">
                          <a href="/login">Log In</a>
                          <a href="/signup">Sign up</a>
                        </div>

                      )}
                  </>
                </div>
              </NavItem>
            </Nav>
          </NavbarCollapse>
          <div className="authLinks desktop" >
            <>
              {cookie ?
                (
                  <div className="log-auth">
                    {/* <p>{email}</p> */}
                    <div>
                      <Logout />
                      {admin && <a href="/admin-panel">Admin Panel</a>}
                    </div>
                  </div>
                ) : (

                  <div className="main-auth">
                    <a href="/login">Log In</a>
                    <a href="/signup">Sign up</a>
                  </div>

                )}
            </>
          </div>

        </Container>
      </BsNavbar>
    </>
  );
}

export default Header;