import * as React from 'react';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

const SinglePost = (props: any) => {
    const { title, description, content, image, permalink, date } = props.props;

    return (
        <>
            <div className="image-wrap">
            <Link to={{ pathname: "/blog/" + permalink }}><img src={image} alt="featured-image"></img></Link>
            </div>
            <div className="post-content">
            <Link to={{ pathname: "/blog/" + permalink }}><h3>{title}</h3></Link>
                <p>{description + "..."}</p>
                <Link to={{ pathname: "/blog/" + permalink }} className="see-more">See more</Link>

            </div>
            <div className='post-meta'>
                <p>{date}</p>
            </div>
        </>
    )
}

export default SinglePost;