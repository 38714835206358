import * as React from 'react';
import Main from "../../../assets/Images/main.png";

const Subscribe = () => {
    return (
      <div className="subscribe">
        <div>
          <p className="title">
            Be the first to know about <span>Crypto news everyday</span>
          </p>
          <p className="sub-title">
            Get crypto analysis, news and updates right to your inbox! Sign up
            here so you don't miss a single newsletter.
          </p>
          <form>
            <input type="email" id="email" name="email" />
            <input type="submit" value="Subscribe now" />
          </form>
        </div>
        <div>
          <img src={Main}></img>
        </div>
      </div>
    );
}

export default Subscribe;