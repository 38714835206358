import * as React from 'react';
import { useState } from 'react';
import FormInput from '../../components/shared/FormInput';
import Button from '../../components/shared/button';
import { userCalls } from '../../api/api';
import { useRouter } from '../../components/custom-hooks/useRouter';

const ResetPassword = () => {
    const router: any = useRouter();

    const [values, setValues] = useState({
        password: '',
        confirmPassword: '',
        token: router.query.token,
        userId: router.query.id,
    });

    console.log(values.token, values.userId, 'token userId')

    const [reqSent, setReqSent] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const handleResetPassword = (e: React.FormEvent) => {
        e.preventDefault();

        const { password, confirmPassword } = values;
        var letter = /[a-z]/;
        var upper = /[A-Z]/;
        var number = /[0-9]/;

        if (password === '' || password.length < 7 || !letter.test(password) || !upper.test(password) || !number.test(password) || password !== confirmPassword) {
            if (password !== confirmPassword) {
                console.log("Please make sure passwords match.");
            }
            if (password.length < 7) {
                console.log('Password has to be minimum 8 charachters long');
            }
            if (!letter.test(password)) {
                console.log("Please make sure password includes a lowercase letter.");
            }
            if (!upper.test(password)) {
                console.log('Password needs to have at least one Uppercase Letter');
            }
            if (!number.test(password)) {
                console.log("Please make sure Password Includes a Digit");
            }
            if (password === '') {
                console.log("A password is required.");
            }
            console.log('error');
        } else {
            userCalls.resetPassword(values)
                .then(() => {
                    setValues({ ...values, password: '', confirmPassword: '' });
                    setReqSent(true);
                    setError(false); 
                })
                .catch((err) => {
                    console.log(err, 'client error');
                    setError(true); 
                    setReqSent(false);
                });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="Form">
            <div>
                <div className="title">
                    <h1>Change Password</h1>
                </div>
                <form className="sign-up-form">
                    <FormInput
                        type="password"
                        value={values.password}
                        label="New Password"
                        name="password"
                        onChange={handleChange}
                    />
                    <FormInput
                        type="password"
                        value={values.confirmPassword}
                        label="Confirm Password"
                        name="confirmPassword"
                        onChange={handleChange}
                    />

                    <p>{reqSent && 'Your password was successfuly reset.'}</p>
                    <p>{error && 'There was an error'}</p>
                    {/* Da se dodade error poraka koga ima vneseno pogresen email, password  */}

                </form>
                <div className="footer">
                    <div className="submit">
                        <Button type="submit" handleClick={handleResetPassword} label="Change Password" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;