import * as React from 'react';
import abbreviateNumber from '../../../helpers/abbreviated';
import numberWithCommas from '../../../helpers/numberCommas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faLongArrowAltDown, faLongArrowAltUp, faSignal } from '@fortawesome/free-solid-svg-icons';
import Bitcoin from '../../../assets/Images/bitcoin-logo.png';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

function TodaysPrices(props: any) {
  const infoProps = props.props;
  const defi = Math.trunc(infoProps.defiVolume24);
  const stable = Math.trunc(infoProps.stableCoinVolume24);
  const volume24 = Math.trunc(infoProps.volume24);

  const defiPercentageChange = (defi / volume24) * 100;
  const stableCoinPercentageChange = (stable / volume24) * 100;

  return (
    <div className="TodaysPrices">
      <div className="title">
        <p>Today's Cryptocurrency Prices by Market Cap</p>
      </div>
      <div className="box-container">
        <div className="small-box">
          <p className="box-title">Global crypto market cup</p>
          <div className="box-content">
            <div className="icon">{infoProps.marketCapYesterday.toFixed(2) > 0 ? (
              <><FontAwesomeIcon className="rotate-up" icon={faLongArrowAltUp  as IconProp} /><FontAwesomeIcon icon={faSignal  as IconProp} /></>
            ) : (

              <><FontAwesomeIcon className="rotate-down" icon={faLongArrowAltDown  as IconProp} /><FontAwesomeIcon className="rotate" icon={faSignal  as IconProp} /></>
            )}</div>
            <div className="small-container">
              <p className="duration">24h {infoProps.marketCapYesterday > 0 ? "high" : "low"}</p>
              <div className="specifications">
                <p>${abbreviateNumber(Number(Math.trunc(infoProps.marketCap).toPrecision(3)))}</p>
                <p style={{ color: infoProps.marketCapYesterday > 0 ? "#16c784" : "#ea3943" }}>
                  {infoProps.marketCapYesterday > 0 ? (
                    <FontAwesomeIcon icon={faCaretUp  as IconProp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown  as IconProp} />
                  )}
                  {Math.abs(infoProps.marketCapYesterday).toFixed(2) + "%"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="small-box">
          <p className="box-title">Total crypto market volume</p>
          <div className="box-content">
            <div className="icon">{infoProps.totalVolume24Yesterday.toFixed(2) > 0 ? (
              <><FontAwesomeIcon className="rotate-up" icon={faLongArrowAltUp  as IconProp} /><FontAwesomeIcon icon={faSignal  as IconProp} /></>
            ) : (

              <><FontAwesomeIcon className="rotate-down" icon={faLongArrowAltDown  as IconProp} /><FontAwesomeIcon className="rotate" icon={faSignal  as IconProp} /></>
            )}</div>
            <div className="small-container">
              <p className="duration">24h {infoProps.totalVolume24Yesterday > 0 ? "high" : "low"}</p>
              <div className="specifications">
                <p>${abbreviateNumber(Number(Math.trunc(infoProps.volume24).toPrecision(4)))}</p>
                <p style={{ color: infoProps.totalVolume24Yesterday > 0 ? "#16c784" : "#ea3943" }}>
                  {infoProps.totalVolume24Yesterday.toFixed(2) > 0 ? (
                    <FontAwesomeIcon icon={faCaretUp  as IconProp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown  as IconProp} />
                  )}
                  {Math.abs(infoProps.totalVolume24Yesterday).toFixed(2) + "%"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="small-box">
          <p className="box-title">Total volume in DeFi</p>
          <div className="box-content">
            <div className="icon"><FontAwesomeIcon icon={faSignal  as IconProp} /></div>
            <div className="small-container">
              <p className="duration">24h volume</p>
              <div className="specifications">
                <p>${abbreviateNumber(Number(Math.trunc(infoProps.defiVolume24).toPrecision(4)))}</p>
                <p>{defiPercentageChange.toFixed(2) + '%'}</p>
              </div>
              <div className="range-s">
                <span style={{ width: defiPercentageChange.toFixed(2) + '%' }}></span>
                <span style={{ width: 100 + '%' }}></span>
              </div>
            </div>
          </div>
        </div>

        <div className="small-box">
          <p className="box-title">Volume of all stable coins</p>
          <div className="box-content">
            <div className="icon"><FontAwesomeIcon icon={faSignal  as IconProp} /></div>
            <div className="small-container">
              <p className="duration">24h volume</p>
              <div className="specifications">
                <p>${abbreviateNumber(Number(Math.trunc(infoProps.stableCoinVolume24).toPrecision(4)))}</p>
                <p>{stableCoinPercentageChange.toFixed(2) + '%'}</p>
              </div>
              <div className="range-s">
                <span style={{ width: stableCoinPercentageChange.toFixed(2) + '%' }}></span>
                <span style={{ width: 100 + '%' }}></span>
              </div>
            </div>
          </div>
        </div>

        <div className="small-box">
          <p className="box-title">Bitcoin's price</p>
          <div className="box-content">
            <div className="icon"><img src={Bitcoin} alt="bitcoin" /></div>
            <div className="small-container">

              <div className="specifications-s">
                <p>${numberWithCommas(infoProps.bitcoin.toFixed(2))}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="small-box">
          <p className="box-title">Bitcoin's dominance</p>
          <div className="box-content">
            <div className="icon">{infoProps.btcDominancePercentChange > 0 ? (
              <><FontAwesomeIcon className="rotate-up" icon={faLongArrowAltUp  as IconProp} /><FontAwesomeIcon icon={faSignal  as IconProp} /></>
            ) : (

              <><FontAwesomeIcon className="rotate-down" icon={faLongArrowAltDown  as IconProp} /><FontAwesomeIcon className="rotate" icon={faSignal  as IconProp} /></>
            )}</div>
            <div className="small-container">
              <p className="duration">24h {infoProps.btcDominancePercentChange > 0 ? "high" : "low"}</p>
              <div className="specifications">
                <p>{Number(infoProps.btcDominance).toFixed(2) + "%"}</p>
                <p style={{ color: infoProps.btcDominancePercentChange > 0 ? "#16c784" : "#ea3943" }}>
                  {infoProps.btcDominancePercentChange.toFixed(2) > 0 ? (
                    <FontAwesomeIcon icon={faCaretUp  as IconProp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown  as IconProp} />
                  )}{Math.abs(infoProps.btcDominancePercentChange).toFixed(2) + "%"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <p>
              The global crypto market cap is <span>${abbreviateNumber(Number(Math.trunc(infoProps.marketCap).toPrecision(3)))}</span>
              , a <span style={{ color: infoProps.marketCapYesterday > 0 ? "#16c784" : "#ea3943" }}>
                {infoProps.marketCapYesterday.toFixed(2) > 0 ? (
                  <FontAwesomeIcon icon={faCaretUp} />
                ) : (
                  <FontAwesomeIcon icon={faCaretDown} />
                )}
                {Math.abs(infoProps.marketCapYesterday).toFixed(2) + "%"}
              </span>
              <span> {infoProps.marketCapYesterday > 0 ? "increase" : "decrease"} over the last day.</span>
            </p> */}
      {/* <p> */}
      {/* The total crypto market volume over the last 24 hours is $
              {abbreviateNumber(Number(Math.trunc(infoProps.volume24).toPrecision(4)))}, which makes
              a <span style={{ color: infoProps.totalVolume24Yesterday > 0 ? "#16c784" : "#ea3943" }}>
                {infoProps.totalVolume24Yesterday.toFixed(2) > 0 ? (
                  <FontAwesomeIcon icon={faCaretUp} />
                ) : (
                  <FontAwesomeIcon icon={faCaretDown} />
                )}
                {Math.abs(infoProps.totalVolume24Yesterday).toFixed(2) + "%"}
              </span>
              <span> {infoProps.totalVolume24Yesterday > 0 ? "increase" : "decrease"}. </span> */}
      {/* The total volume in DeFi is currently $
              {abbreviateNumber(Number(Math.trunc(infoProps.defiVolume24).toPrecision(4)))}, {defiPercentageChange.toFixed(2) + '%'} of
              the total crypto market 24-hour volume. The volume of all stable coins is now
              ${abbreviateNumber(Number(Math.trunc(infoProps.stableCoinVolume24).toPrecision(4)))}, which is {stableCoinPercentageChange.toFixed(2) + '%'} of
              the total crypto market 24-hour volume.
            </p> */}
      {/* <p>
              Bitcoin's price is currently ${numberWithCommas(infoProps.bitcoin.toFixed(2))}.
            </p> */}
      {/* <p>
              Bitcoin’s dominance is currently {Number(infoProps.btcDominance).toFixed(2) + "%"}
              , {infoProps.btcDominancePercentChange > 0 ? "an increase" : "a decrease"} of{'\xa0'}
              <span style={{ color: infoProps.btcDominancePercentChange > 0 ? "#16c784" : "#ea3943" }}>
                {infoProps.btcDominancePercentChange.toFixed(2) > 0 ? (
                  <FontAwesomeIcon icon={faCaretUp} />
                ) : (
                  <FontAwesomeIcon icon={faCaretDown} />
                )} {Math.abs(infoProps.btcDominancePercentChange).toFixed(2) + "%"}
              </span> over the day.
            </p> */}
    </div>
  );
}

export default TodaysPrices;