import * as React from "react";
//@ts-ignore
import TimeRange from "react-timeline-range-slider";
import { useEffect, useState } from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import { CryptoCalls } from "../../../api/api";
import { shortNumber } from "../../../helpers/decimals";

const Graph = ({ id }: any) => {

  const selectedStart = 0;
  const selectedEnd = 10;
  //setSelectedInterval(selectedStart, selectedEnd);

  let quoteData: { Price: any; }[]= [];

  const [historical, setHistorical] = useState(Object);
  const fetchHistorical = async (id: any) => {
    if(id != undefined)  {
      await CryptoCalls.getHistoricalQuotes({type: 'historical-quotes', uniqueId: id}).then((info: any) => {
        let quote = JSON.parse(info.info[0].quotesHistorical).map((obj: any) => obj.quote.USD.price);
        let quoteTime = JSON.parse(info.info[0].quotesHistorical).map((obj: any) => obj.timestamp);
        for(let i =0; i < quote.length; i++) {
        let data = {Price:quote[i], month: quoteTime[i].substr(0,10)+' '+quoteTime[i].substr(11,8)}
        data.Price = shortNumber(quote[i])
        quoteData.push(data)
        }
        setHistorical(quoteData);
      });
    }
  };


  useEffect(() => {
    fetchHistorical(id);
  }, [id])

  const [selectedInterval, setSelectedInterval] = useState();
  const onChangeCallback = (selectedInterval: any) => {
    setSelectedInterval(selectedInterval);
  };

  // const chartContainer = {
  //   width: "50%",
  //   margin: "0 auto",
  // };

  return (
    <>
      <div className="chart">
        <AreaChart
          width={1340}
          height={350}
          data={historical}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#16c784" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#16c784" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="month" />
          <YAxis domain={['auto', 'auto']}/>
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Price"
            dot={{ stroke: 'red', strokeWidth: 1 }}
            stroke="#16c784"
            fill="url(#colorUv)"
            strokeWidth={3}
            activeDot={{ r: 6 }}
          />
        </AreaChart>
      </div>
      <div className="time-range">
        <TimeRange
          ticksNumber={36}
          selectedInterval={selectedInterval}
          //timelineInterval={timelineInterval}
          //onUpdateCallback={this.errorHandler}
          onChangeCallback={onChangeCallback}
        //disabledIntervals={disabledIntervals}
        />
      </div>
    </>
  );
};

export default Graph;
