import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import { CryptoCalls } from '../../api/api';
import BoxTable from '../home/Components/BoxTable';
import Subscribe from '../home/Components/Subscribe';
import EmptyWatchlist from '../watchlist/EmptyWatchlist';
import BoxTableWatchlist from '../home/Components/BoxTableWatchlist';

const Watchlist = () => {
  const [loading, setLoading] = useState<boolean>(true);

  //values to send to api
  const [valueWatchlist, setValueWatchlist] = useState({ userEmail: Cookies.get("email") });
  const [valueCrypto, setValueCrypto] = useState({ type: "coin-quotes-watchlist", id: "" });
  const [valueTrending, setValueTrending] = useState({ type: "trending" });
  const [valueMostVisited, setValueMostVisited] = useState({ type: "trending_most_visited" });
  const [valueGainersLosers, setvalueGainersLosers] = useState({ type: "trending_gainers_losers" });

  const [valuesMetadata, setValuesMetadata] = useState({ type: "metadata", id: "" })
  const [valuesStats, setValuesStats] = useState({ type: "stats", id: "" })

  //metadata state
  const [cryptoId, setCryptoId] = useState({ id: "" });
  const [trendingIds, setTrendingIds] = useState({ id: "" });
  const [mostVisitedIds, setMostVisitedIds] = useState({ id: "" });
  const [biggestGainersIds, setBiggestGainersIds] = useState({ id: "" });

  //stats state
  // const [cryptoStats, setCryptoStats] = useState({id: "" });
  // const [trendingStats, setTrendingStats] = useState({id: "" });
  // const [mostVisitedStats, setMostVisitedStats] = useState({id: ""});
  // const [biggestGainersStats, setBiggestGainersStats] = useState({id: ""});

  //data
  const [cryptos, setCryptos] = useState(Object);
  const [allCoinData, setMetadata] = useState(Object);
  const [stats, setStats] = useState(Object);
  const [trending, setTrending] = useState(Object);
  const [mostVisited, setMostVisited] = useState(Object);
  const [gainersLosers, setGainersLosers] = useState(Object);
  const [isInWatchList, SetisInWatchList] = useState(Array);
  const [allData, setAllCoinData] = useState(Object);
  const [allDataCoin, setAllCoinDataCoin] = useState(true);


  const getWatchList = useCallback(() => {
    if (valueWatchlist.userEmail) {
      CryptoCalls.getWatchListCoinIdByUser(valueWatchlist).then((info: any) => {
        let data = info.data.map((obj: any) => obj.coinId);
        SetisInWatchList(data); //also setwatchlist on click
        setValueCrypto((prevState) => {
          return {
            ...prevState,
            id: data.toString(),
          };
        });
      });
    } else if (valueWatchlist.userEmail === undefined) {
      SetisInWatchList([])
    }
  }, [valueWatchlist]);

  const getFetchCrypto = useCallback(() => {
    setLoading(true);
    var b = valueCrypto.id.split(',').map(function(item) {
      return parseInt(item, 10);
    });
    let example = {id: b}
    if (valueCrypto.id.length) {
      CryptoCalls.getWatchlistCoins(example).then((info) => {
        setCryptos(info.data);
        function arrayMap(arrayOfCryptoIds: any) {
          var arr = Object.values(info.data).map((v: any) => ({
            id: v.id,
          }));
          arrayOfCryptoIds = arr.map(function (obj: any) {
            return obj.id;
          });
          return arrayOfCryptoIds;
        }
        setCryptoId((prevState) => {
          return {
            ...prevState,
            id: arrayMap(info.data).toString(),
          };
        });
      });
    }
  }, [valueCrypto]);

  // const getFetchTrending = useCallback(() => {
  //   if (isInWatchList.length > 0) {
  //     CryptoCalls.getTrending(valueTrending).then((info: any) => {
  //       setTrending(info.data.data.data);
  //       function arrayMap(arrayOfCryptoIds: any) {
  //         var arr = Object.values(info.data.data.data).map((v: any) => ({
  //           id: v.id,
  //         }));
  //         arrayOfCryptoIds = arr.map(function (obj: any) {
  //           return obj.id;
  //         });
  //         return arrayOfCryptoIds;
  //       }
  //       setTrendingIds((prevState) => {
  //         return {
  //           ...prevState,
  //           id: arrayMap(info.data.data.data).toString(),
  //         };
  //       });
  //     });
  //   }
  // }, [valueTrending, isInWatchList]);

  const getFetchTrending = useCallback(() => {
    if (isInWatchList.length > 0) {
      // CryptoCalls.getTrending(valueTrending).then((info: any) => {
      //   setTrending(info.data.data.data);
      //   function arrayMap(arrayOfCryptoIds: any) {
      //     var arr = Object.values(info.data.data.data).map((v: any) => ({
      //       id: v.id,
      //     }));
      //     arrayOfCryptoIds = arr.map(function (obj: any) {
      //       return obj.id;
      //     });
      //     return arrayOfCryptoIds;
      //   }
      //   setTrendingIds((prevState) => {
      //     return {
      //       ...prevState,
      //       id: arrayMap(info.data.data.data).toString(),
      //     };
      //   });
      // });
      CryptoCalls.getTrendingDatabase(valueTrending).then((data: any) => {
        setLoading(true);
        setTrending(data.data);
      });
    }
  }, [valueTrending, isInWatchList]);

  // const getFetchMostVisited = useCallback(() => {
  //   if (isInWatchList.length > 0) {
  //     CryptoCalls.getBiggestGainers(valueMostVisited).then((info: any) => {
  //       setMostVisited(info.data.data.data);
  //       function arrayMap(arrayOfCryptoIds: any) {
  //         var arr = Object.values(info.data.data.data).map((v: any) => ({
  //           id: v.id,
  //         }));
  //         arrayOfCryptoIds = arr.map(function (obj: any) {
  //           return obj.id;
  //         });
  //         return arrayOfCryptoIds;
  //       }
  //       setMostVisitedIds((prevState) => {
  //         return {
  //           ...prevState,
  //           id: arrayMap(info.data.data.data).toString(),
  //         };
  //       });
  //     });
  //   }
  // }, [valueMostVisited, isInWatchList]);

  const getFetchMostVisited = useCallback(() => {
    if (isInWatchList.length > 0) {
      // CryptoCalls.getBiggestGainers(valueMostVisited).then((info: any) => {
      //   setMostVisited(info.data.data.data);
      //   function arrayMap(arrayOfCryptoIds: any) {
      //     var arr = Object.values(info.data.data.data).map((v: any) => ({
      //       id: v.id,
      //     }));
      //     arrayOfCryptoIds = arr.map(function (obj: any) {
      //       return obj.id;
      //     });
      //     return arrayOfCryptoIds;
      //   }
      //   setMostVisitedIds((prevState) => {
      //     return {
      //       ...prevState,
      //       id: arrayMap(info.data.data.data).toString(),
      //     };
      //   });
      // });
      CryptoCalls.getMostVisitedDatabase(valueMostVisited).then((data: any) => {
        setLoading(true);
        setMostVisited(data.data);
      });
    }
  }, [valueMostVisited, isInWatchList]);

  // const getFetchBiggestGainers = useCallback(() => {
  //   if (isInWatchList.length > 0) {
  //     CryptoCalls.getGainersLosers(valueGainersLosers).then((info: any) => {
  //       setGainersLosers(info.data.data.data);
  //       function arrayMap(arrayOfCryptoIds: any) {
  //         var arr = Object.values(info.data.data.data).map((v: any) => ({
  //           id: v.id,
  //         }));
  //         arrayOfCryptoIds = arr.map(function (obj: any) {
  //           return obj.id;
  //         });
  //         return arrayOfCryptoIds;
  //       }
  //       setBiggestGainersIds((prevState) => {
  //         return {
  //           ...prevState,
  //           id: arrayMap(info.data.data.data).toString(),
  //         };
  //       });
  //     });
  //   }
  // }, [valueGainersLosers, isInWatchList]);

  const getFetchBiggestGainers = useCallback(() => {
    if (isInWatchList.length > 0) {
      // CryptoCalls.getGainersLosers(valueGainersLosers).then((info: any) => {
      //   setGainersLosers(info.data.data.data);
      //   function arrayMap(arrayOfCryptoIds: any) {
      //     var arr = Object.values(info.data.data.data).map((v: any) => ({
      //       id: v.id,
      //     }));
      //     arrayOfCryptoIds = arr.map(function (obj: any) {
      //       return obj.id;
      //     });
      //     return arrayOfCryptoIds;
      //   }
      //   setBiggestGainersIds((prevState) => {
      //     return {
      //       ...prevState,
      //       id: arrayMap(info.data.data.data).toString(),
      //     };
      //   });
      // });
      CryptoCalls.getBigestGainerDatabase(valueGainersLosers).then((data: any) => {
        setLoading(true);
        setGainersLosers(data.data);
        // setBiggestGainersLogoId(arrayMap(data.data));
      });
    }
  }, [valueGainersLosers, isInWatchList]);

  useEffect(() => {
    if (cryptoId.id && trendingIds.id.length && mostVisitedIds.id.length && biggestGainersIds.id.length) {
      const stringOfIds = ''.concat(trendingIds.id, ',', cryptoId.id, ',', mostVisitedIds.id, ',', biggestGainersIds.id);
      setValuesMetadata((prevState) => {
        return {
          ...prevState,
          id: stringOfIds,
        };
      });
      setValuesStats((prevState) => {
        return {
          ...prevState,
          id: stringOfIds,
        };
      });
    }
  }, [cryptoId.id, trendingIds.id, mostVisitedIds.id, biggestGainersIds.id])

  const FetchMetadata = useCallback(async () => {
    if (valuesMetadata.id) {
      await CryptoCalls.getMetadata(valuesMetadata).then((info: any) => {
        console.log(info, 'metadata trending')
        setMetadata((prevState: any) => {
          return {
            ...prevState, ...info.data.data.data
          }
        })
      });
    }
  }, [valuesMetadata])

  const FetchStats = useCallback(async () => {
    if (valuesStats.id) {
      await CryptoCalls.getStats(valuesStats).then((info: any) => {
        console.log(info, 'stats trending')
        setStats((prevState: any) => {
          return {
            ...prevState, ...info.data.data.data
          }
        })
      });
    }
    setLoading(false);
  }, [valuesStats])

  //stats
  // const getFetchCryptoStats = useCallback(() => {
  //   if (cryptoStats.id.length) {
  //     CryptoCalls.getStats(cryptoStats).then((info: any) => {
  //       setStats((prevState: any) => {
  //         return {
  //           ...prevState,
  //           ...info.data.data.data,
  //         };
  //       });
  //     });
  //   }
  // }, [cryptoStats]);

  // const getFetchTrendingStats = useCallback(() => {
  //   if (trendingStats.id.length) {
  //     CryptoCalls.getStats(trendingStats).then((info: any) => {
  //       setStats((prevState: any) => {
  //         return {
  //           ...prevState,
  //           ...info.data.data.data,
  //         };
  //       });
  //     });
  //   }
  // }, [trendingStats]);

  // const getFetchMostVisitedStats = useCallback(() => {
  //   if (mostVisitedStats.id.length) {
  //     CryptoCalls.getStats(mostVisitedStats).then((info: any) => {
  //       setStats((prevState: any) => {
  //         return {
  //           ...prevState,
  //           ...info.data.data.data,
  //         };
  //       });
  //     });
  //   }
  // }, [mostVisitedStats]);

  // const getFetchBiggestGainersStats = useCallback(() => {
  //   if (biggestGainersStats.id.length) {
  //     CryptoCalls.getStats(biggestGainersStats).then((info: any) => {
  //       setStats((prevState: any) => {
  //         return {
  //           ...prevState,
  //           ...info.data.data.data,
  //         };
  //       });
  //     });
  //   }
  // }, [biggestGainersStats]);

  //metadata
  // const getFetchCryptoMetadata = useCallback(() => {
  //   if (cryptoMetadata.id.length) {
  //     CryptoCalls.getMetadata(cryptoMetadata).then((info) => {
  //       setMetadata((prevState: any) => {
  //         return {
  //           ...prevState,
  //           ...info.data.data.data,
  //         };
  //       });
  //     });
  //   }
  //   setLoading(false);
  // }, [cryptoMetadata]);

  // const getFetchTrendingMetadata = useCallback(() => {
  //   if (trendingMetadata.id.length) {
  //     CryptoCalls.getMetadata(trendingMetadata).then((info) => {
  //       setMetadata((prevState: any) => {
  //         return {
  //           ...prevState,
  //           ...info.data.data.data,
  //         };
  //       });
  //     });
  //   }
  // }, [trendingMetadata]);

  // const getFetchMostVisitedMetadata = useCallback(() => {
  //   if (mostVisitedMetadata.id.length) {
  //     CryptoCalls.getMetadata(mostVisitedMetadata).then((info) => {
  //       setMetadata((prevState: any) => {
  //         return {
  //           ...prevState,
  //           ...info.data.data.data,
  //         };
  //       });
  //     });
  //   }
  // }, [mostVisitedMetadata]);

  // const getFetchBiggestGainersMetadata = useCallback(() => {
  //   if (biggestGainersMetadata.id.length) {
  //     CryptoCalls.getMetadata(biggestGainersMetadata).then((info) => {
  //       setMetadata((prevState: any) => {
  //         return {
  //           ...prevState,
  //           ...info.data.data.data,
  //         };
  //       });
  //     });
  //   }
  // }, [biggestGainersMetadata]);

  useEffect(() => {
    getWatchList();
  }, [getWatchList]);

  useEffect(() => {
    getFetchCrypto();
  }, [getFetchCrypto]);

  useEffect(() => {
    getFetchTrending();
  }, [getFetchTrending]);

  useEffect(() => {
    getFetchMostVisited();
  }, [getFetchMostVisited]);

  useEffect(() => {
    getFetchBiggestGainers();
  }, [getFetchBiggestGainers]);

  useEffect(() => {
    FetchMetadata();
  }, [FetchMetadata])

  useEffect(() => {
    FetchStats();
  }, [FetchStats])

  //metadata
  // useEffect(() => {
  //   getFetchCryptoMetadata();
  // }, [getFetchCryptoMetadata]);

  // useEffect(() => {
  //   getFetchTrendingMetadata();
  // }, [getFetchTrendingMetadata]);

  // useEffect(() => {
  //   getFetchMostVisitedMetadata();
  // }, [getFetchMostVisitedMetadata]);

  // useEffect(() => {
  //   getFetchBiggestGainersMetadata();
  // }, [getFetchBiggestGainersMetadata]);

  //stats
  // useEffect(() => {
  //   getFetchCryptoStats();
  // }, [getFetchCryptoStats]);

  // useEffect(() => {
  //   getFetchTrendingStats();
  // }, [getFetchTrendingStats]);

  // useEffect(() => {
  //   getFetchMostVisitedStats();
  // }, [getFetchMostVisitedStats]);

  // useEffect(() => {
  //   getFetchBiggestGainersStats();
  // }, [getFetchBiggestGainersStats]);


  // const getAllCoinDatabase = async () => {
  //   const coinIds = ''
  //   await CryptoCalls.getAllCoinData({ coinIds }).then((info: any) => {
  //     setAllCoinData(info.data);
  //     setLoading(false);
  //     setAllCoinDataCoin(false);
  //   })
  // }

  // useEffect(() => {
  //   getAllCoinDatabase()
  // }, []);

  const coinNumber = 1;
  const BoxTableProps = {
    cryptos,
    trending,
    allData,
    coinNumber,
    stats,
    mostVisited,
    gainersLosers,
    loading,
    isInWatchList
  };

  return (
    <div className='crypto-box'>
      <h1>My Watchlist</h1>
      <div className="box">
        {isInWatchList === undefined || isInWatchList.length === 0 ? <EmptyWatchlist /> : <BoxTableWatchlist {...BoxTableProps} />}
      </div>
      <Subscribe />
    </div>
  );
}

export default Watchlist;