import * as React from 'react';
import TextField from "@mui/material/TextField";
import { Link } from 'react-router-dom';



function SearchCoinComponent(boxNavProp: any, input: any) {

  const allData = boxNavProp.props

  //create a new array by filtering the original array
  const filteredData = allData.filter((el: any) => {
    // if no input the return the original
    if (boxNavProp.input === '') {
      // return el;
    }
    //return the item which contains the user input
    else {
      return el.name.toLowerCase().includes(boxNavProp.input)
    }
  })


  return (
    Object.keys(filteredData).length != 0 ? 
    <ul className='search-listing'>
      {filteredData.slice(0, 5).map((item: any) => (
        <li key={item.id}>
          <img className='logo' src={item.logo}/>
          <Link key={item.id}
            to={{
              pathname: '/currencies/' + item.name.toLowerCase(),
            }}>
              {item.name}
          </Link></li>
      ))}
    </ul> : <ul style={{display: 'none'}}></ul>
  )
}

export default SearchCoinComponent;

function useState(arg0: string): [any, any] {
  throw new Error('Function not implemented.');
}
