import * as React from "react";

const AboutCoin = (props: any) => {
    const { description } = props.props;
    return (
        <div className="about">
            {/* Izlistaj tektualni elementi so html tagovi od baza za coin po id */}
            {/* Stilizirani tagovi vo scss se h1, h2, h3, h4, ul, ol, li, a */}
            <h1>Lorem ipsum dolor sit amet</h1>
            <p>{description}</p>
            <h2>Lorem ipsum dolor sit amet</h2>
            <p>{description}</p>
            <p>{description}</p>
            <h3>Lorem ipsum dolor sit amet</h3>
            <p>{description}</p>
            <p>{description}</p>
            <h4>Lorem ipsum dolor sit amet</h4>
            <ul>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
            </ul>
            <ol>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
            </ol>
            <a href="#">Lorem ipsum dolor sit amet</a>
        </div>
    );
}

export default AboutCoin;