import * as React from 'react';
import { useState } from 'react';
import FormInput from '../../components/shared/FormInput';
import Button from '../../components/shared/button';
import { userCalls } from '../../api/api';

const ForgotPassword = () => {
    const [values, setValues] = useState({
        email: '',
    });
    const [reqSent, setReqSent] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const handleForgotPassword = (e: React.FormEvent) => {
        e.preventDefault();
        const { email } = values;
        if (email === '') {
            console.log("Email field is empty");
        } else {
            userCalls.forgotPassword(values)
                .then((data) => {
                    setValues({ ...values, email: '' });
                    setReqSent(true);
                    setError(false); 
                })
                .catch((err) => {
                    console.log(err, 'client error');
                    setError(true); 
                    setReqSent(false);
                });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="Form">
            <div>
                <div className="title">
                    <h1>Forgot Password</h1>
                </div>
                <form >
                    <FormInput
                        type="email"
                        value={values.email}
                        label="Email"
                        name="email"
                        onChange={handleChange}
                    />

                    <p>{reqSent && 'Forgot password request was sent. Please check your email.'}</p>
                    <p>{error && 'There was an error'}</p>
                    {/* Da se dodade error poraka koga ima vneseno pogresen email */}

                </form>
                <div className="footer">
                    <div className="submit">
                        <Button type="submit" handleClick={handleForgotPassword} label="Reset Password" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;