import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Cookies from 'js-cookie';
import TextField from "@mui/material/TextField";
import SearchCoinComponent from './SearchCoinComponent';
import { relative } from 'path';
import SkeletonElement from '../../../helpers/skeletonElement';



interface Props {
  allData: any;
  setRecords: any;
  itemsPerPage: number;
  fetchCategory: any;
  cookie: any;
  coinName: any;
}

const BoxNav = ({ setRecords, allData, itemsPerPage, fetchCategory, cookie, coinName }: Props) => {
  const [activeId, setActiveId] = useState(1);
  const [cookieActive, setCookieActive] = useState(coinName ? coinName : 'Cryptocurrencies');

  const items = [
    { name: 'Cryptocurrencies', id: 1 },
    { name: 'Ethereum', id: 2 },
    { name: 'BSC', id: 3 },
    { name: 'Kusama', id: 4 },
    { name: 'Solana', id: 5 },
    { name: 'Elrond', id: 6 },
    { name: 'Avalanche', id: 7 },
  ];

  const [inputText, setInputText] = useState("");
  const [boxNavProp, setBoxNavProp] = useState(Object);

  let inputHandler = (e: { target: { value: string; }; }) => {
    var lowerCase = e.target.value.toLowerCase();
    // let example = document.querySelectorAll(".search-listing");
    // example.style.display = "block"
    setTimeout(() => {
      setInputText(lowerCase);
    }, 400)
  };

  useEffect(() => {
    if (allData.length != 0) {
      setBoxNavProp(allData)
    }
  }, [allData]);

  return (
    <div className="box-head">
      <div>

        <div>
          <TextField
            id="outlined-basic"
            onChange={inputHandler}
            variant="outlined"
            fullWidth
            label="Search"
            size='small'
          />
          {Object.keys(boxNavProp).length != 0 ? (<SearchCoinComponent props={boxNavProp} input={inputText} />) : undefined}
        </div>

        {/* {Object.keys(boxNavProp).length != 0 ? (
          <div>
            <TextField
              id="outlined-basic"
              onChange={inputHandler}
              variant="outlined"
              fullWidth
              label="Search"
            />
            {Object.keys(boxNavProp).length != 0 ? (<SearchCoinComponent props={boxNavProp} input={inputText} />) : undefined}
          </div>) : (<SkeletonElement type='search' />)
        } */}

        <Link to={cookie ? "/watchlist" : '/login'}><FontAwesomeIcon icon={faStar as IconProp} />Watchlist</Link>


        <Link to={cookie ? "/portfolio" : '/login'}><FontAwesomeIcon icon={faChartPie as IconProp} />Portfolio</Link>

      </div>
      <div className="box-menu">
        {items.map((item, i) => (
          <button key={i} onClick={(() => { setCookieActive(item.name); setActiveId(item.id); fetchCategory(item.name) })} className={item.name === cookieActive ? "active" : ""}>{item.name}</button>
          // Object.keys(boxNavProp).length != 0 ? (
          //   <button key={i} onClick={(() => { setCookieActive(item.name); setActiveId(item.id); fetchCategory(item.name) })} className={item.name === cookieActive ? "active" : ""}>{item.name}</button>
          // ) : <button key={i} onClick={(() => { setCookieActive(item.name); setActiveId(item.id); fetchCategory(item.name) })} className={item.name === cookieActive ? "active" : ""}><SkeletonElement type='category'/></button>))}
        ))}
      </div>
      <div>
        <form>
          <label>Show rows
            <select value={itemsPerPage} onChange={setRecords}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </label>
        </form>
        <button><FontAwesomeIcon icon={faSlidersH as IconProp} /> Filters</button>
      </div>
    </div>
  )
}

export default BoxNav;