import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import "bootstrap/scss/bootstrap.scss";
import { Accordion, Card } from 'react-bootstrap'

const Contact = () => {
  const starColorStyle = {
    color: '#DE0606'
  }

  const questionTitleStyle = {
    fontWeight: 600,
    fontSize: '20px'
  }

  const questionBoxStyle = {
    borderBottom: '1px solid #707070',
    marginBottom: '20px'
  }

  const iconStyle = {
    color: '#000000',
    opacity: '0.6',
    fontSize: '20px'
  }

  const cardHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none',
    background: 'rgb(249, 252, 255)',
    padding: '0',
    paddingBottom: '0'
  }

  const cardBodyStyle = {
    background: 'rgb(249, 252, 255)',
    color: '#000000',
    padding: '0',
    borderBottom: '1px solid #707070',
    paddingBottom: '8px'
  }

  const CardStyle = {
    border: 'none',
    marginBottom: '30px'
  }

  const cardHeader = {
    background: 'rgb(249, 252, 255)',
    border: 'none',
    padding: '0',
    paddingBottom: '8px'
  }

  const tabs = [
    { id: '1', label: "Lorem ipsum dolor", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod" },
    { id: '2', label: "Lorem ipsum dolor", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod" },
    { id: '3', label: "Lorem ipsum dolor", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod" },
    { id: '4', label: "Lorem ipsum dolor", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod" }
  ];

    return (
      <div className='contact-page'>
        <div className="contact-wrapper">
          <h1 className='heading'>CONTACT US</h1>
          <div className='form-wraper'>
              <div className='form'>
                <form>
                  <label className='with-padding'>
                    <p>FIRST NAME <span style={starColorStyle}>*</span></p>
                    <input type="text" name="name" />
                  </label>
                  <label className='with-padding'>
                    <p>LAST NAME <span style={starColorStyle}>*</span></p>
                    <input type="text" name="name" />
                  </label>
                  <label className='without-padding'>
                    <p>EMAIL <span style={starColorStyle}>*</span></p>
                    <input type="email" name="email" />
                  </label>
                  <label className='without-padding'>
                    <p>MESSAGE <span style={starColorStyle}>*</span></p>
                    <input type="message" name="message" />
                  </label>
                  <label className='without-padding'>
                    <p>ADDITIONAL DETAILS</p>
                    <textarea name="details" />
                  </label>
                  <input className='input-styling' type="submit" value="Send Message" />
                </form>
              </div>

              <div className='question'>
                  <h2 className='heading'>How Can We Help?</h2>
                  <p className='explanation'>
                    Please select a topic below related to your inquiry. <br/> If you don't find what you need, fill out our contact form.
                  </p>
                  {tabs.map(tab => (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item className='card-style' eventKey="1">
                        <Accordion.Header className='card-header' >{tab.label}</Accordion.Header>
                        <Accordion.Body className='card-body' >
                          {tab.description}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    // <Accordion key={tab.id} defaultActiveKey={tab.id}>
                    //   <Card style={CardStyle}> 
                    //     <Card.Header style={cardHeader}>
                    //       <Accordion.Toggle as={Card.Header} style={cardHeaderStyle} variant="link" eventKey={tab.id}>
                    //         <span style={questionTitleStyle}>{tab.label}</span>
                    //         <FontAwesomeIcon icon={faAngleRight} style={iconStyle}/>
                    //       </Accordion.Toggle>
                    //     </Card.Header>
                    //     <Accordion.Collapse eventKey={tab.id} style={{background: 'rgb(249, 252, 255)'}}>
                    //       <Card.Body style={cardBodyStyle}>{tab.description}</Card.Body>
                    //     </Accordion.Collapse>
                    //   </Card>
                    // </Accordion>
                  ))}
              </div>
          </div>
        </div>
      </div>
    );
}
 
export default Contact;