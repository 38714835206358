import * as React from 'react';
import numberWithCommas from '../../../helpers/numberCommas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import ShowMore from 'react-show-more-button/dist/module';
import convertExponentialToDecimal from '../../../helpers/decimals';
import monthDiff from '../../../helpers/calculateMonth';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const CoinStats = (props: any) => {
  const {
    name,
    symbol,
    stats,
    id,
    circulating_supply,
    totalSupply,
    maxSupply,
    price,
    volume_24h,
    market_cap_dominance,
    rank,
    marketCap,
    fully_diluted_market_cap,
  } = props.props;

  return (
    <>
      {stats !== undefined &&
        Object.values(stats).map((line: any, index: any) => {
          const statId = line.id;
          if (id === statId) {
            const { price_change, percent_change, open, close, low, high, high_timestamp, low_timestamp } = line.periods.all_time.quote.USD;
            const {
              price_change: price_change_7d,
              percent_change: percent_change_7d,
              open: open_7d,
              close: close_7d,
              low: low_7d,
              high: high_7d,
            } = line.periods["7d"].quote.USD;
            const {
              price_change: price_change_24h,
              percent_change: percent_change_24h,
              open: open_24h,
              close: close_24h,
              low: low_24h,
              high: high_24h,
            } = line.periods["24h"].quote.USD;
            const {
              price_change: price_change_30d,
              percent_change: percent_change_30d,
              open: open_30d,
              close: close_30d,
              low: low_30d,
              high: high_30d,
            } = line.periods["30d"].quote.USD;
            const {
              price_change: price_change_90d,
              percent_change: percent_change_90d,
              open: open_90d,
              close: close_90d,
              low: low_90d,
              high: high_90d,
            } = line.periods["90d"].quote.USD;
            const {
              price_change: price_change_365d,
              percent_change: percent_change_365d,
              open: open_365d,
              close: close_365d,
              low: low_365d,
              high: high_365d,
            } = line.periods["365d"].quote.USD;
            
            const highHistory = monthDiff(new Date(), new Date(high_timestamp));
            const lowHistory = monthDiff(new Date(), new Date(low_timestamp));

            const allTimeHighDate = new Date(high_timestamp).toDateString().split(" ").slice(1).join(" ");
            const allTimeLowDate = new Date(low_timestamp).toDateString().split(" ").slice(1).join(" ");

            return (
              <div key={index} className="Coin-statistics">
                <p className="main-title">{symbol} Price Statistics</p>
                <div className="items">
                  <div className="items-group">
                    <p className="items-title">{name} Price Today</p>
                    <div className="item"><p>{name} Price</p><p>${convertExponentialToDecimal(price)}</p></div>
                    <div className="item"><p><span>Price change</span><span>24h</span></p>
                      <p>
                        <span>${convertExponentialToDecimal(price_change_24h)}</span>
                        <span style={{ color: percent_change_24h > 0 ? "#16c784" : "#ea3943" }}>
                          {percent_change_24h > 0 ? (
                            <FontAwesomeIcon icon={faCaretUp as IconProp} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretDown as IconProp} />
                          )}
                          {Math.abs(percent_change_24h.toFixed(2))}
                        </span>
                      </p>
                    </div>
                    <div className="item"><p>24h Low / 24h High</p>
                      <p><span>${convertExponentialToDecimal(low_24h)}</span><span className="sep">/</span>
                        <span>${convertExponentialToDecimal(high_24h)}</span></p></div>
                    <div className="item"><p><span>Trading Volume</span></p>
                      <p>
                        <span>${numberWithCommas(volume_24h.toFixed(0))}</span>
                      </p>
                    </div>
                    {/* <div className="item"><p>Volume / Market Cap</p><p></p></div> */}
                    <div className="item"><p>Market Dominance</p><p>{market_cap_dominance === 0 ? '--' : numberWithCommas(market_cap_dominance.toFixed(2)) + '%'}</p></div>
                    <div className="item"><p>Market Rank</p><p>#{rank}</p></div>
                  </div>

                  <div className="items-group">
                    <p className="items-title">{name} Market Cap</p>
                    <div className="item"><p>Market Cap</p><p><span>{ marketCap ? numberWithCommas('$' + marketCap.toFixed(0)) : 'No Data'}</span></p></div>
                    <div className="item"><p>Fully Diluted Market Cap</p><p><span>{ fully_diluted_market_cap ? numberWithCommas('$' + fully_diluted_market_cap.toFixed(0)) : 'No Data'}</span></p></div>
                  </div>
                  {/* <ShowMore maxHeight={64} className="show-more">
                    <div className="items-group">
                      <p className="items-title">{name} Price Yesterday</p>
                      <div className="item"><p>Yesterday's Low / High</p><p><span>?</span><span className="sep">/</span><span>?</span></p></div>
                      <div className="item"><p>Yesterday's Open / Close</p><p><span>?</span><span className="sep">/</span><span>?</span></p></div>
                      <div className="item"><p>Yesterday's Change</p><p>?</p></div>
                      <div className="item"><p>Yesterday's Volume</p><p>?</p></div>
                    </div>
                    <div className="items-group">
                      <p className="items-title">{name} Price History</p>
                      <div className="item"><p>7d Low / 7d High</p><p><span>${convertExponentialToDecimal(low_7d)}</span><span className="sep">/</span><span>${convertExponentialToDecimal(high_7d)}</span></p></div>
                      <div className="item"><p>30d Low / 30d High</p><p><span>${convertExponentialToDecimal(low_30d)}</span><span className="sep">/</span><span>${convertExponentialToDecimal(high_30d)}</span></p></div>
                      <div className="item"><p>90d Low / 90d High</p><p><span>${convertExponentialToDecimal(low_90d)}</span><span className="sep">/</span><span>${convertExponentialToDecimal(high_90d)}</span></p></div>
                      <div className="item"><p>52 Week Low / 52 Week High</p><p><span>${convertExponentialToDecimal(low_365d)}</span><span className="sep">/</span><span>${convertExponentialToDecimal(high_365d)}</span></p></div>
                      <div className="item">
                        <p><span>All Time High</span><span>{allTimeHighDate}</span><span>({highHistory})</span></p>
                        <p><span>{high ? '$' + convertExponentialToDecimal(high) : 'No Data'}</span><span>?</span></p>
                      </div>
                      <div className="item">
                        <p><span>All Time Low</span><span>{allTimeLowDate}</span><span>({lowHistory})</span></p>
                        <p><span>{low ? '$' + convertExponentialToDecimal(low) : 'No Data'}</span><span>?</span></p>
                      </div>
                      <div className="item"><p><span>{name} ROI</span><FontAwesomeIcon icon={faQuestionCircle as IconProp} /></p><p>?</p></div>
                    </div>
                    <div className="items-group">
                      <p className="items-title">{name} Supply</p>
                      <div className="item"><p>Circulating Supply</p><p>{circulating_supply ? numberWithCommas(circulating_supply.toFixed(0)) : 'No Data'}</p></div>
                      <div className="item"><p>Total Supply</p><p>{totalSupply ? numberWithCommas(totalSupply.toFixed(0)) : 'No Data'}</p></div>
                      <div className="item"><p>Max Supply</p><p>{maxSupply ? numberWithCommas(maxSupply.toFixed(0)) : 'No Data'}</p></div>
                    </div>
                  </ShowMore> */}
                </div>
              </div>
            );
          }
        })}
    </>
  )
}

export default CoinStats;