import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Like = (props: any) => {
    const { name } = props.props;
    return (
        <div className="like-dislike">
            <div>
                <p>How do you feel about {name} today?</p>
                <p>Vote to see community results</p>
            </div>
            <div>
                {/* Da se napravi funkcija na klik na button da ispraka informacija do baza za like i dislike spored id na coin */}
                {/* Potoa da izdade rezultat na mestoto na buttons, a vo p elementite da pisuva descripcija*/}
                <button className="like"><FontAwesomeIcon icon={faThumbsUp  as IconProp} /><span>Good</span></button>
                <button className="dislike"><FontAwesomeIcon icon={faThumbsDown  as IconProp} /><span>Bad</span></button>
            </div>
        </div>
    );
}

export default Like;