import * as React from 'react';
import { useState } from 'react';

const CoinPageNav = (props: any) => {
  const { activeId, setActiveId } = props.props; //get setActiveId in another way
  const [activeButton, setActiveButton] = useState(1); //works with this but the data won't show

  const items = [
    { name: 'Overview', id: 1 },
    { name: 'Market', id: 2 },
    { name: 'Historical Data', id: 3 },
    { name: 'Holders', id: 4 },
    { name: 'Wallets', id: 5},
    { name: 'News', id: 6 },
    { name: 'Socials', id: 7 },
    { name: 'Ratings', id: 8 },
    { name: 'Analysis', id: 9},
    { name: 'Price Estimates', id: 10},
  ];

  return (
    <div className="coinPageNav">
      <div>
        {items.map((item, i) => (
          <button key={i} onClick={(() => {setActiveId(item.id); setActiveButton(item.id)})} className={activeButton === item.id ? "active" : ""}>{item.name}</button>
        ))}
      </div>
    </div>
  );
}

export default CoinPageNav;