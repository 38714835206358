import * as React from 'react';
import TrendingSpotlight from './TrendingSpotlight';
import MostVisitedSpotlight from './MostVisitedSpotlight';
import GainersLosersSpotlight from './GainersLosersSpotlight';

const Spotlight = (props: any) => {
    const { trending, mostVisited, gainersLosers, metadata, setActiveId, setActiveButton, activeButton} = props.props;

    const TrendingCurrenciesProps = {
      trending,
      metadata,
      gainersLosers,
      mostVisited,
      setActiveId,
      setActiveButton, 
    };

    const MostVisitedCurrenciesProps = {
      mostVisited,
      metadata,
      trending,
      gainersLosers,
      setActiveId,
      setActiveButton, 
    };

    const GainersLosersProps = {
      gainersLosers,
      metadata,
      trending,
      mostVisited,
      setActiveId,
      setActiveButton, 
    };
    
    return (
        <div style={{display: 'flex', justifyContent: 'space-around', width: '1400px', margin: '3% auto'}}>
          <TrendingSpotlight props={TrendingCurrenciesProps}/>
          <MostVisitedSpotlight props={MostVisitedCurrenciesProps}/>
          <GainersLosersSpotlight props={GainersLosersProps}/>
        </div>
    )
}

export default Spotlight; 