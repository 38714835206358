import * as React from 'react';
import { useState } from 'react';

const CategoryButtons = ({ categories, onSelectCategory, activeId }: any) => {

  return (
    <div className="blog-categories">
      {categories.map((i: any) => (
        // <div style={{ margin: '10px' }} key={i.id}>
        <button key={i.id} onClick={(() => { onSelectCategory(i.id) })} className={activeId === i.id ? "active" : ""}>
          {i.title}
        </button>
        // </div>
      ))}
    </div>
  )
};

export default CategoryButtons;