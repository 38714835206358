import React from 'react';
import { blogCalls } from '../../api/api';
import { useState, useEffect } from 'react';
import Posts from './components/posts';
import CategoryButtons from './components/CategoryButtons';
// import arrayBufferToBase64 from '../../components/elements/buffer';

const Blog = () => {
  const [posts, setPosts] = useState(Array);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [showCategory, setShowCategory] = useState(false);
  const [activeId, setActiveId] = useState(0);
  const [categories, setCategories] = useState<any>();
  let selectedCategory;

  useEffect(() => {
    blogCalls.getBlogPosts().then((res: any) => {
      setPosts(res.data)
    })
      .catch((err: any) => {
        console.log(err);
      });

    blogCalls.getBlogCategories().then((res: any) => {
      setCategories(res.categories)
    })
      .catch((err: any) => {
        console.log(err);
      });
  }, [])

  if (categories !== undefined) {
    selectedCategory = categories.find((i: any) => i.id === selectedCategoryId);
  }

  const onSelectCategory = (id: any) => {
    id == 0 ? setShowCategory(false) : setShowCategory(true)
    setSelectedCategoryId(id);
    setActiveId(id);
  }

  return (
    <div className='blog'>
      <div className='main-container'>
        <div className='heading'>
          <h1>Blog</h1>
        </div>
        {categories !== undefined && <CategoryButtons categories={categories} onSelectCategory={onSelectCategory} activeId={activeId} />}
        <Posts posts={posts} selectedCategory={selectedCategory} showCategory={showCategory} />
      </div>
    </div>
  );
}

export default Blog;