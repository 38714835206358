import * as React from "react";
import News from "../../../assets/Images/news.png";

const WatchedCoins = (props: any) => {
    return (
        <div className="watch-list">
            <p className="title">People Are Also Watching</p>
            {/* Da se reproducira celata lista so istite klasi na tagovite za da prikazuva watched coins direknlo od baza */}
            {/* SCSS e napisan da odgovara za iminjata na klasite */}
            <ul className="list">
                <li className="list-item">
                    <a href="#">
                        <div className="coin">
                            <div className="coin-image">
                                <img src={News} alt="coin" />
                            </div>
                            <div className="coin-details">
                                <p className="name">Name</p>
                                <p className="price">price</p>
                                <p className="percent" style={{ background: "red" }}>percent</p>
                            </div>
                        </div>
                        <div className="coin-chart">
                            <img src={News} alt="coin" />
                        </div>
                    </a>
                </li>
                <li className="list-item">
                    <a href="#">
                        <div className="coin">
                            <div className="coin-image">
                                <img src={News} alt="coin" />
                            </div>
                            <div className="coin-details">
                                <p className="name">Name</p>
                                <p className="price">price</p>
                                <p className="percent" style={{ background: "red" }}>percent</p>
                            </div>
                        </div>
                        <div className="coin-chart">
                            <img src={News} alt="coin" />
                        </div>
                    </a>
                </li>
                <li className="list-item">
                    <a href="#">
                        <div className="coin">
                            <div className="coin-image">
                                <img src={News} alt="coin" />
                            </div>
                            <div className="coin-details">
                                <p className="name">Name</p>
                                <p className="price">price</p>
                                <p className="percent" style={{ background: "red" }}>percent</p>
                            </div>
                        </div>
                        <div className="coin-chart">
                            <img src={News} alt="coin" />
                        </div>
                    </a>
                </li>
                <li className="list-item">
                    <a href="#">
                        <div className="coin">
                            <div className="coin-image">
                                <img src={News} alt="coin" />
                            </div>
                            <div className="coin-details">
                                <p className="name">Name</p>
                                <p className="price">price</p>
                                <p className="percent" style={{ background: "red" }}>percent</p>
                            </div>
                        </div>
                        <div className="coin-chart">
                            <img src={News} alt="coin" />
                        </div>
                    </a>
                </li>
                <li className="list-item">
                    <a href="#">
                        <div className="coin">
                            <div className="coin-image">
                                <img src={News} alt="coin" />
                            </div>
                            <div className="coin-details">
                                <p className="name">Name</p>
                                <p className="price">price</p>
                                <p className="percent" style={{ background: "red" }}>percent</p>
                            </div>
                        </div>
                        <div className="coin-chart">
                            <img src={News} alt="coin" />
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default WatchedCoins;