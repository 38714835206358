import * as React from 'react';
import { useState } from 'react';
import { useLocation } from "react-router";
import { CryptoCalls } from '../../../api/api';
import { useEffect } from 'react';
import numberWithCommas from '../../../helpers/numberCommas';
import convertExponentialToDecimal from '../../../helpers/decimals';
import CoinDetails from './CoinDetails';

const HistoricalData = (props: any) => {
    //const location: any = useLocation();
    //const { id, name } = location.state;

    const { id, name } = props.props;

    const [historical, setHistorical] = useState();
    const [historicalData, setHistoricalData] = useState({
        type: 'historical',
        uniqueId: id
    });
    
    async function fetchHistorical() {
        await CryptoCalls.getHistorical(historicalData)
            .then((info: any) => {
                setHistorical(info.data.data.data.quotes);
        });
    }

    useEffect(() => {
        fetchHistorical();
    }, []);


    return (
        <>
            <div className="historical-data">
                <div className="layout">
                    <p className="title">Historical Data for {name}</p>
                    <table className='box-table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Open<span>*</span></th>
                                <th>High</th>
                                <th>Low</th>
                                <th>Close<span>**</span></th>
                                <th>Volume</th>
                                <th>Market Cap</th>
                            </tr>
                        </thead>
                        <tbody >
                            {historical !== undefined && Object.values(historical).map((line: any, index: any) => {
                                const { timestamp, open, high, low, close, market_cap, volume } = line.quote.USD;
                                return (
                                    <>

                                        <tr key={index}>
                                            <td>
                                                {new Date(timestamp)
                                                    .toDateString()
                                                    .split(" ")
                                                    .slice(1)
                                                    .join(" ")}
                                            </td>
                                            <td>${convertExponentialToDecimal(open)}</td>
                                            <td>${convertExponentialToDecimal(high)}</td>
                                            <td>${convertExponentialToDecimal(low)}</td>
                                            <td>${convertExponentialToDecimal(close)}</td>
                                            <td>${numberWithCommas(volume.toFixed())}</td>
                                            <td>${numberWithCommas(market_cap.toFixed())}</td>
                                        </tr>

                                    </>
                                );
                            })
                            }
                        </tbody>
                    </table>
                    <p className="table-sub"><span>*</span><span>Earliest data in range (UTC time)</span></p>
                    <p className="table-sub"><span>**</span><span>Latest data in range (UTC time)</span></p>
                </div>
            </div>
        </>
    )
}

export default HistoricalData;