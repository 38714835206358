import * as React from 'react';
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import { userCalls } from '../../api/api';

interface Istyle {
    linkStyle?: any;
}

export default function Logout(props: Istyle) {

    let history = useHistory();
    function handleLogOut() {
        const email = {
            email: Cookies.get('email')
        }
        console.log(email.email)
        Cookies.remove('cookie');
        Cookies.remove('email');
        Cookies.remove('admin');
        history.push("/login");
        history.go(0);
        
        userCalls.logoutUser(email)
                .then((res) => {
                    history.push('/');
                    history.go(0);
                })
                .catch((err) => {
                    // if (err.response.status === 400) {
                    //     setError(err.response.data.message)
                    // } if (err.response.status === 404) {
                    //     setError(err.response.data.message)
                    // }
                });
    }

    return (
        <>
            {/* needs style */}
            <button type="button" style={props.linkStyle} onClick={handleLogOut}>
                Log Out
            </button>
        </>
    )
}
