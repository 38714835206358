import React from "react";

const FormInput = ({ value, label, name, type, autoComplete, defaultValue, maxLength, onChange, min }: { value?: string, defaultValue?: string, label: string, name: string, type: any, autoComplete?: string, maxLength?: number, onChange: React.ChangeEventHandler<HTMLElement>, min?: number }) => (
  <div className="form-group">
    {label && <label htmlFor="input-field">{label}<span className="req">*</span></label>}
    <input
      type={type}
      value={value}
      name={name}
      className="form-control"
      //removed placeholder
      autoComplete={autoComplete}
      maxLength={maxLength}
      onChange={onChange}
      min={min}
      defaultValue={defaultValue}
    />
  </div>
);

export default FormInput;