import * as React from 'react';
import { blogCalls } from '../../../api/api';
import { useState, useEffect } from 'react';
import { useRouter } from '../../../components/custom-hooks/useRouter';
import Parser from 'html-react-parser';
import { baseUrl } from '../../../config.js';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
// import arrayBufferToBase64 from '../../components/elements/buffer';

const BlogPost = () => {
    const router: any = useRouter();

    const [value, setValue] = useState({ title: router.query.title });

    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [content, setContent] = useState('');
    const [categoryId, setCategoryId] = useState();
    const [date, setDate] = useState<string>();
    const [featured_image, set_featured_image] = useState<string>();
    const [postId, setPostId] = useState(Number);
    const isLoggedIn = Cookies.get('cookie');

    useEffect(() => {
        if (value) {
            blogCalls.getBlogPost(value).then((res: any) => {
                console.log(res.data[0], 'res.data[0]');

                setTitle(res.data[0].title);
                setDescription(res.data[0].description);
                setContent(res.data[0].content);
                // var base64Flag = 'data:image/jpeg;base64,';
                // var imageStr = arrayBufferToBase64(res.data.featured_image.data);
                // set_featured_image(base64Flag + imageStr);
                setCategoryId(res.data[0].categoryId)
                setPostId(res.data[0].id)
                set_featured_image(baseUrl + 'blog/' + res.data[0].featured_image);
                const date_created = new Date(res.data[0].createdAt).toLocaleString();
                setDate(date_created);
            })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    }, [value])

    return (
        <div className="single-blog-post">
            <div className='main-container'>
                <div className='post'>
                    <div className='title'>
                        <h1>{title}</h1>
                    </div>
                    <div className='post-meta'>
                        <p>Published on: {date}</p>
                    </div>
                    {isLoggedIn ? (
                        <div>
                            <Link to={{ pathname: "/admin-panel", state: { title, content, featured_image, categoryId, description, postId } }}><button>Edit</button></Link>
                        </div>
                    ) : (<div></div>)}
                    <div className='post-image'> <img src={featured_image} alt="img"></img></div>
                    <div className='post-content'>{Parser(content)}</div>
                </div>
                <div className='post-sidebar'></div>
            </div>
        </div>
    )
}

export default BlogPost;