import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const ChartNav = (period: any) => {
  return (
    <div className="chartNav">
      <ul className="left">
        <li className="active">Price</li>
        <li className="">Market Cap</li>
        <li className="">Trading View</li>
      </ul>

      <ul className="right">
        <li>1D</li>
        <li>7D</li>
        <li>1M</li>
        <li>3M</li>
        <li>1Y</li>
        <li>YTD</li>
        <li className="active">ALL</li>
        <li>
          <FontAwesomeIcon icon={faCalendar as IconProp} />
        </li>
        <li>LOG</li>
      </ul>
    </div>
  )
}

export default ChartNav;