import * as React from "react";
import { useState } from "react";
import CoinPageInfo from "./CoinPageInfo";
import CoinPriceInfo from "./CoinPriceInfo";
import CoinStatsInfo from "./CoinStatsInfo";

const CoinDetails = (props: any) => {
  const [sliderRecord, setSliderRecord] = useState(1);
  const setSliderRecords = (e: any) => {
    setSliderRecord(e.target.value);
  };

  const {
    id,
    index,
    img,
    stats,
    name,
    rank,
    symbol,
    category,
    url,
    description,
    price,
    percent_change_24h,
    explorers,
    percent_change_7d,
    tags,
    reddit,
    twitter,
    github,
    chat,
    whitepaper,
    marketCap,
    volume_24h,
    circulating_supply,
    fully_diluted_market_cap,
    max_supply,
    total_supply,
    handleWatchList,
    isInWatchList,
    setClicked,
    clicked
  } = props.props;

  const CoinPriceProps = {
    symbol,
    name,
    id,
    rank,
    img,
    price,
    category,
    percent_change_24h,
    stats,
    sliderRecord,
    setSliderRecords,
    handleWatchList,
    isInWatchList,
    setClicked,
    clicked
  };

  const infoProps = {
    url,
    tags,
    description,
    reddit,
    twitter,
    github,
    chat,
    whitepaper,
    explorers
  };

  const CoinStatsProps = {
    marketCap,
    volume_24h,
    fully_diluted_market_cap,
    circulating_supply,
    max_supply,
    symbol,
    total_supply,
  };

  return (
    <div className="coin-details">
      <CoinPriceInfo {...CoinPriceProps} />
      <div className="info-holder">
        <CoinPageInfo {...infoProps} />
        <CoinStatsInfo {...CoinStatsProps} />
      </div>
    </div>
  );
};

export default CoinDetails;
