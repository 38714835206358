import React from "react";
import { useEffect, useCallback, useState } from "react";
import { blogCalls, CryptoCalls } from "../../api/api";
import Box from "./Components/Box";
// @ts-ignore
import ReactPaginate from "react-paginate";
import "bootstrap/scss/bootstrap.scss";
import TrendingTabs from "./Components/TrendingTabs";
import HomeNews from "./Components/HomeNews";
import Subscribe from "./Components/Subscribe";
import FAQ from "./Components/FAQ";
import Cookies from "js-cookie";
import TodaysPrices from "./Components/TodaysPrices";
import { useHistory } from "react-router-dom";
import { useRouter } from "../../components/custom-hooks/useRouter";
import arrayMap from "../../helpers/arrayMap";
import SkeletonElement from "../../helpers/skeletonElement";

const Home = (props: any) => {
  const [cookie] = useState(Cookies.get('cookie'));
  const [cryptos, setCryptos] = useState(Object);
  const [allCoinData, setAllCoinData] = useState(Object);
  const [loading, setLoading] = useState<boolean>(true)
  const [end, setEnd] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [coinByCmcRank, setCoinByCmcRank] = useState(Object);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState(5000);
  const [newPage, setNewPage] = useState(false);
  const [trending, setTrending] = useState(Object);
  const [blogs, setBlogs] = useState(Object);
  const [mostVisited, setMostVisited] = useState(Object);
  const [gainersLosers, setGainersLosers] = useState(Object);
  const [isInWatchList, SetisInWatchList] = useState(Array);
  const [showPagination, setShowPagination] = useState(true);
  const [coinByCategory, setCoinByCategory] = useState(true);
  const [categoryCrypto, setCategoryCrypto] = useState(true);
  const [allCoinDatabase, setAllCoinDatabase] = useState(true);

  const coinNumber = currentPage == 1 ? 0 + 1 : ((currentPage - 1) * itemsPerPage) + 1;

  const [globalStats, setGlobalStats] = useState<Object>(props.props);

  //values to send to api
  const [valueCryptosByCmcRank, setValueCryptosByCmcRank] = useState({ pageNumber: 1, recordsPerPage: itemsPerPage, pageRange: 1 });
  const [coinData, setCoinData] = useState({ pageNumber: 1, recordsPerPage: itemsPerPage, pageRange: 1 });
  const [valueTrending] = useState({ type: "trending" });
  const [valueMostVisited] = useState({ type: "trending_most_visited" });
  const [valueGainersLosers] = useState({ type: "trending_gainers_losers" });
  const [valueWatchlist] = useState({ userEmail: Cookies.get("email") });
  const [valueCoinByCategory, setValueCoinByCategory] = useState({ categoryName: "", categoryEnd: itemsPerPage, pageNumber: 1 });

  const [cryptoLogoId, setCryptoLogoId] = useState(Array);
  const [trendingLogoId, setTrendingLogoId] = useState(Array);
  const [mostVisitedLogoId, setMostVisitedLogoId] = useState(Array);
  const [biggestGainersLogoId, setBiggestGainersLogoId] = useState(Array);
  const [categoryLogoId, setCategoryLogoId] = useState(Array);

  const handlePageChange = (pageNumber: any) => {
    setEnd(false);
    setCurrentPage(pageNumber.selected + 1);
  };

  const setRecords = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemsPerPage(Number(e.target.value));
  };

  const fetchCategory = (blockchainNetwork: String) => {
    setLoading(true);
    switch (blockchainNetwork) {
      case 'Cryptocurrencies':
        setCoinByCategory(true);
        setCategoryCrypto(true);
        break;
      case 'Ethereum':
        setValueCoinByCategory({ ...valueCoinByCategory, categoryName: 'ETH' });
        setCategoryCrypto(false);
        break;
      case 'BSC':
        setValueCoinByCategory({ ...valueCoinByCategory, categoryName: 'BNB' });
        setCategoryCrypto(false);
        break;
      case 'Kusama':
        setValueCoinByCategory({ ...valueCoinByCategory, categoryName: 'KSM' });
        setCategoryCrypto(false);
        break;
      case 'Solana':
        setValueCoinByCategory({ ...valueCoinByCategory, categoryName: 'SOL' });
        setCategoryCrypto(false);
        break;
      case 'Avalanche':
        setValueCoinByCategory({ ...valueCoinByCategory, categoryName: 'AVAX' });
        setCategoryCrypto(false);
        break;
    }
  }

  //get coin by category
  const getCoinByCategory = useCallback(() => {
    if (valueCoinByCategory.categoryName || !/^\s*$/.test(valueCoinByCategory.categoryName)) {
      CryptoCalls.getCoinByCategory(valueCoinByCategory).then((info: any) => {
        setCoinByCategory(false);
        setCryptos(info.info);
        setCategoryLogoId(arrayMap(info.info));
      });
      CryptoCalls.getCountCoinByCategory(valueCoinByCategory).then((info: any) => {
        setTotalCount(info.info.length)
      });
    }
  }, [valueCoinByCategory]);

  //get coin by cmcrank
  const getFetchCryptosByCmcRank = useCallback(() => {
    CryptoCalls.getCoinByCmcRank(valueCryptosByCmcRank).then((info) => {
      setLoading(true);
      setCryptos(info.data);
      setCryptoLogoId(arrayMap(info.data));
    });
  }, [valueCryptosByCmcRank])

  const getFetchTrending = useCallback(() => {
    CryptoCalls.getTrendingDatabase(valueTrending).then((data: any) => {
      setLoading(true);
      setTrending(data.data);
      setTrendingLogoId(arrayMap(data.data));
    });
  }, [valueTrending])

  const getFetchMostVisited = useCallback(() => {
    CryptoCalls.getMostVisitedDatabase(valueMostVisited).then((data: any) => {
      setLoading(true);
      setMostVisited(data.data);
      setMostVisitedLogoId(arrayMap(data.data));
    });
  }, [valueMostVisited])

  const getFetchBiggestGainers = useCallback(() => {
    CryptoCalls.getBigestGainerDatabase(valueGainersLosers).then((data: any) => {
      setLoading(true);
      setGainersLosers(data.data);
      setBiggestGainersLogoId(arrayMap(data.data));
    });
  }, [valueGainersLosers])

  useEffect(() => {
    if (cryptoLogoId.length && trendingLogoId.length && mostVisitedLogoId.length && biggestGainersLogoId.length) {
      const combine = cryptoLogoId.concat(trendingLogoId, mostVisitedLogoId, biggestGainersLogoId);
      const removeDuplicates = [...Array.from(new Set(combine))];
      getCoinDataDatabase();
    } if (categoryLogoId.length && trendingLogoId.length && mostVisitedLogoId.length && biggestGainersLogoId.length) {
      const combine = categoryLogoId.concat(trendingLogoId, mostVisitedLogoId, biggestGainersLogoId);
      const removeDuplicates = [...Array.from(new Set(combine))];
      getCoinDataDatabase();
    }
  }, [cryptoLogoId, categoryLogoId, trendingLogoId, mostVisitedLogoId, biggestGainersLogoId])

  //get all coin from database, from this data we are loading the coin by category, most visited and trending, this function load only once
  const getAllCoinDatabase = async () => {
    if(allCoinDatabase) {
      const coinIds = ''
      await CryptoCalls.getAllCoinData({ coinIds }).then((info: any) => {
        setAllCoinData(info.data);
        setLoading(false);
        setAllCoinDatabase(false)
      })
    }
  }

  useEffect(() => {
    getAllCoinDatabase()
  }, [allCoinDatabase]);

  const FetchBlogPosts = useCallback(async () => {
    await blogCalls.getLatestBlogs().then((info: any) => {
      setLoading(true);
      setBlogs(info.blogs);
    });
  }, [])

  const getWatchList = useCallback(() => {
    if (valueWatchlist.userEmail) {
      CryptoCalls.getWatchListCoinIdByUser(valueWatchlist).then((info: any) => {
        let data = info.data.map((obj: any) => obj.coinId);
        SetisInWatchList(data);
      });
    } else if (valueWatchlist.userEmail === undefined) {
      SetisInWatchList([])
    }
  }, [valueWatchlist]);

  //get coin by cmcrank, this function only load when we are geting the coin by cmcrank and not for coin by category
  //that is done by checking the categoryCrypto, categoryCrypto change the value in the fetchCategory function, categoryCrypto is sent to BoxTable.tsx to in order to know what to render
  const getCoinDataDatabase = async () => {
    if (categoryCrypto) {
      await CryptoCalls.getCoinCmcRankOnSwitch(coinData).then((info: any) => {
        setCoinByCmcRank(info.data);
        setLoading(false);
      })
    }
  }

  useEffect(() => {
    getCoinDataDatabase()
  }, []);

  useEffect(() => {
    if (coinByCategory) {
      setValueCryptosByCmcRank(prevState => {
        return {
          ...prevState,
          pageNumber: currentPage,
          recordsPerPage: itemsPerPage,
          pageRange: currentPage == 1 ? 1 : (currentPage - 1) * itemsPerPage,
        }
      })
    }
    if (!coinByCategory) {
      setValueCoinByCategory(prevState => {
        return {
          ...prevState,
          pageNumber: currentPage,
          recordsPerPage: itemsPerPage,
          pageRange: currentPage == 1 ? 1 : (currentPage - 1) * itemsPerPage,
          categoryEnd: itemsPerPage
        }
      })
    }
  }, [currentPage, itemsPerPage, coinByCategory]);

  useEffect(() => {
    if (coinByCategory) {
      getFetchCryptosByCmcRank();
      setEnd(true);
      setNewPage(false);
      setLoading(false);
      setShowPagination(true);
    }
  }, [getFetchCryptosByCmcRank, coinByCategory]);

  useEffect(() => {
    setLoading(false);
    setCoinData(prevState => {
      return {
        ...prevState,
        pageNumber: currentPage,
        recordsPerPage: itemsPerPage,
        pageRange: currentPage == 1 ? 1 : (currentPage - 1) * itemsPerPage,
      }
    })
  }, [currentPage, itemsPerPage]);



  useEffect(() => {
    if (!coinByCategory) {
      getCoinByCategory();
      setEnd(true);
      setNewPage(false);
      setLoading(false);
      setShowPagination(true);
    }
  }, [getCoinByCategory, coinByCategory]);

  useEffect(() => {
    getFetchTrending();
  }, [getFetchTrending])

  useEffect(() => {
    getFetchMostVisited();
  }, [getFetchMostVisited]);

  useEffect(() => {
    getFetchBiggestGainers();
  }, [getFetchBiggestGainers]);

  useEffect(() => {
    getWatchList();
  }, [getWatchList]);

  useEffect(() => {
    getCoinByCategory();
  }, [getCoinByCategory])

  useEffect(() => {
    FetchBlogPosts()
  }, [FetchBlogPosts])

  useEffect(() => {
    if (cryptos != undefined && cryptos[0] != undefined && coinByCategory) {
      setGlobalStats(prevState => {
        return {
          ...prevState,
          bitcoin: JSON.parse(cryptos[0].quote).USD.price
        }
      })
    }
  }, [cryptos]);

  const Boxprops = {
    cryptos: cryptos,
    coinNumber: coinNumber,
    coinByCmcRank: coinByCmcRank,
    allData: allCoinData,
    categoryCrypto: categoryCrypto,
    trending: trending,
    mostVisited: mostVisited,
    gainersLosers: gainersLosers,
    setRecords: setRecords,
    fetchCategory: fetchCategory,
    itemsPerPage: itemsPerPage,
    loading: loading,
    isInWatchList: isInWatchList,
    cookie: cookie,
    coinName: valueCoinByCategory.categoryName
  };

  const TrendingTabsProps = {
    allCoinData: allCoinData,
    trending: trending,
    mostVisited: mostVisited,
    gainersLosers: gainersLosers,
    isInWatchList: isInWatchList
  }

  return (
    <div className="crypto-box">
      <div className="main-container">
        {isError ? (
          <h1>Oopss..</h1>
        ) : (end && (
          <>
            <div className="price-general">
              {(globalStats != undefined && globalStats.hasOwnProperty('bitcoin')) &&
                <TodaysPrices props={globalStats} />}
            </div>

            <div className="second">
              <>

                <Box {...Boxprops} />
                {Object.keys(cryptos).length === 0 ? (<SkeletonElement type='example'/>) : (<div className="details">
                  <p>Showing {coinNumber} - {(coinNumber + itemsPerPage) - 1} out of {totalCount}</p>
                  {showPagination &&
                    <div>
                      <ReactPaginate
                        breakLabel={"..."}
                        pageCount={Math.ceil(totalCount / itemsPerPage)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={4}
                        onPageChange={handlePageChange}
                        forcePage={currentPage - 1}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                      />
                    </div>
                  }
                </div>)}
                
              </>
            </div>
            <div className="first">

              <HomeNews props={{ blogs }} />
              <div className="home-tabs">
                <TrendingTabs props={TrendingTabsProps} />
              </div>

            </div>


          </>
        )
        )}
        {/* <FAQ /> */}
      </div>
      {/* <Subscribe /> */}
    </div>
  );
};

export default Home;
