import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import numberWithCommas from "../../../../helpers/numberCommas";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const GainersLosers = (props: any) => {
  const { gainersLosers, metadata } = props.props;

  const w25 = {
    width: "25px",
    paddingRight: "5px",
  };

  return (
    <div className="table-wraper">
      <h5 className="header">Top Gainers</h5>
      <div className="table">
        <table>
          <thead className="table-head">
            <tr className="general-head">
              <th scope="col" className="table-head-content">#</th>
              <th className="table-head-content">Name</th>
              <th scope="col" className="table-head-content">Price</th>
              <th scope="col" className="table-head-content">24h</th>
              <th scope="col" className="table-head-different">Volume(24h)</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {gainersLosers !== undefined &&
              gainersLosers !== null &&
              Object.values(gainersLosers).map((line: any, index: any) => {
                const { id, name, symbol } = line;
                const { price, percent_change_24h, volume_24h } = JSON.parse(line.quote).USD;
                return (
                  <tr key={index} className="general-body">
                    <td className="table-body-content">{id}</td>
                    <td className="table-body-content">
                      {metadata !== undefined &&
                        Object.values(metadata).map((line: any, index: any) => {
                          const metaId = line.id;
                          if (id === metaId) {
                            const img = line.logo;
                            return (
                              <span key={index}>
                                <img style={w25} src={img} alt="logo" />
                              </span>
                            );
                          }
                        })}
                      {name} {symbol}
                    </td>
                    <td className="table-body-content">{price.toFixed(2)}$</td>
                    <td 
                      style={{
                        color: percent_change_24h > 0 ? "#16c784" : "#ea3943", paddingRight: '120px'
                      }}
                    >
                      {percent_change_24h > 0 ? (
                        <FontAwesomeIcon icon={faCaretUp  as IconProp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown  as IconProp} />
                      )}
                      {Math.abs(percent_change_24h.toFixed(2))}%
                    </td>
                    <td >${numberWithCommas(volume_24h.toFixed(0))}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GainersLosers;
