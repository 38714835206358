import * as React from 'react';

const PortfolioTableHead = () => {
    return (
          <thead>
            <tr>
              <th>Name</th>
              <th scope="col">Price</th>
              <th scope="col">24h</th>
              <th scope="col">Holdings</th>
              <th scope="col">Avg. Buy Price</th>
              <th scope="col">Total Spent</th>
              <th scope="col">Profit/Loss</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
    )
}

export default PortfolioTableHead;