import * as React from "react";

const Analysis = (props: any) => {
    const { name } = props.props;
    return (
        <div className="Analysis">
            <div>
                <p>Check our {name} Analysis</p>
            </div>
            <div>
                <button>Check it out</button>
            </div>
        </div>
    );
}

export default Analysis;