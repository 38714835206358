import * as React from 'react';
import convertExponentialToDecimal from '../../../helpers/decimals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faPlus, faEllipsisV, faExchangeAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const PortfolioTable = (props: any) => {
    const { name, price, percent24, amount, logo, buyPrice, calcProfitLoss, percentage, quantity, symbol, totalSpent, addTransaction,
        id, setRemoveCoin, setDeleteId, setGetTransactions, setShowTransactions, showTransactions } = props.props; 
    return (
        <>
            <td className="cripto">
                <div> 
                    <span className="logo">
                        <img src={logo} alt="logo" width={'40px'} />
                    </span>
                    <span style={{margin: '5px'}}>{name} {symbol}</span>
                </div>
            </td>
            <td className="price">${convertExponentialToDecimal(Number(price))}</td>
            <td className="24h" style={{ color: percent24 > 0 ? "#16c784" : "#ea3943" }}>
                {percent24 > 0 ? (
                    <FontAwesomeIcon icon={faCaretUp  as IconProp} />
                    ) : (
                    <FontAwesomeIcon icon={faCaretDown  as IconProp} />
                )}
                {Number(Math.abs(percent24).toFixed(2))}% 
            </td>
            <td>${amount > 0 ? convertExponentialToDecimal(Number(amount)) : '0'}
                <p>{quantity} {symbol}</p>
            </td>
            <td>${convertExponentialToDecimal(Number(buyPrice))}</td>
            <td>${convertExponentialToDecimal(Number(totalSpent))}</td>
            <td>
                {calcProfitLoss >= 0 ? '+' + '$' + calcProfitLoss.toFixed(2) : '-' + '$' + Math.abs(calcProfitLoss).toFixed(2)}
                <p style={{ color: percentage > 0 ? "#16c784" : "#ea3943" }}>
                    {percentage > 0 ? (
                        <FontAwesomeIcon icon={faCaretUp  as IconProp} />
                        ) : (
                        <FontAwesomeIcon icon={faCaretDown  as IconProp} />
                    )}
                    {Number(Math.abs(percentage.toFixed(2))) + '%'}
                </p>
            </td>
            {/* <td><button><FontAwesomeIcon icon={faEllipsisV} /></button></td> */}
            <td><button onClick={(() => addTransaction(id, name, symbol, logo))}><FontAwesomeIcon icon={faPlus  as IconProp} /></button>
            <button onClick={(() => { setDeleteId(id); setRemoveCoin(true); })}><FontAwesomeIcon icon={faTrash  as IconProp} /></button>
            <button onClick={(() => { !showTransactions ? setShowTransactions(true) : setShowTransactions(false); setGetTransactions({id: id, symbol: symbol}) })}><FontAwesomeIcon icon={faExchangeAlt  as IconProp} /></button></td>
        </>
    )
}

export default PortfolioTable;