import * as React from "react";

const RangeSlider = (props) => {
    const {
        sliderRecord,
        low_24h,
        low_30d,
        low_365d,
        price: currentPrice,
        high_24h,
        high_30d,
        high_365d,
    } = props.props;

    let sliderMin;
    let sliderMax;
    let percentage;

    if (sliderRecord == 1) {
        sliderMin = low_24h;
        sliderMax = high_24h;
        percentage = ((currentPrice - sliderMin) * 100) / (sliderMax - sliderMin) //removed toFixed()
    }
    if (sliderRecord == 2) {
        sliderMin = low_30d;
        sliderMax = high_30d;
        percentage = ((currentPrice - sliderMin) * 100) / (sliderMax - sliderMin) //removed toFixed()
    }
    if (sliderRecord == 3) {
        sliderMin = low_365d;
        sliderMax = high_365d;
        percentage = ((currentPrice - sliderMin) * 100) / (sliderMax - sliderMin) //removed toFixed()
    }

    return ( <div className = "rc-slider" >
        <div className = "rc-slider-rail" >
        < span className = "rc-slider-track"
        style = {
            {
                width: percentage + "%",
            }
        } >
        </span> <span className = "rc-slider-track-after"
        style = {
            {
                left: percentage + "%",
            }
        } >
        </span> </div > </div>
    );
};

const SupplySlider = (props) => {
    const { max_supply, circulating_supply } = props.props;

    const percentage = (circulating_supply * 100) / max_supply;

    return ( <div className = "rc-slider" >
        <div className = "rc-slider-rail" >
        <span className = "rc-slider-track"
        style = {
            {
                width: percentage + "%",
            }
        } >
        </span> <span className = "rc-slider-track-after"
        style = {
            {
                left: percentage + "%",
            }
        } >
        </span> </div > </div>
    );
};

const AllocationSlider = (props) => {
    const { coins, amount: totalAmount } = props.props;

    const alternatingColor = ['#d5d5d5', '#a9a9a9', 'rgb(245 202 202)'];

    return ( <div className = "rc-slider"
        style = {
            { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }
        } >
        <div className = "rc-slider-rail"
        style = {
            { display: 'flex' }
        } > {
            coins !== undefined && Object.values(coins).map((line, index) => {
                const { coinId, amount, symbol } = line;
                const percentage = (amount * 100) / totalAmount;

                return ( <div key = { index }
                    style = {
                        { width: percentage + "%", height: 'inherit', backgroundColor: alternatingColor[index % alternatingColor.length] }
                    } >
                    <div style = {
                        { display: 'flex', marginTop: '10px' }
                    } >
                    <div style = {
                        { backgroundColor: alternatingColor[index % alternatingColor.length], width: '15px', height: '15px', borderRadius: '50%' }
                    } >
                    </div> <span> { symbol } { percentage.toFixed(2) + '%' } </span> </div > </div>
                )
            })
        } </div> </div >
    );
};

export { RangeSlider, SupplySlider, AllocationSlider };