import axios, { AxiosResponse } from 'axios';
import * as Data from '../models/crypto-interface';
import * as User from '../models/user-interface';
import { baseUrl } from '../config.js';

const instance = axios.create({
	baseURL: baseUrl, //'http://192.168.100.74:5000/'
	timeout: 12000,
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
	get: (url: string) => instance.get(url).then(responseBody),
	post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
	put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
	delete: (url: string) => instance.delete(url).then(responseBody),
};

export const CryptoCalls = {
	
	getMetadata: (cryptos: Object): Promise<Data.Root> => requests.post('home', cryptos),
	getCryptos: (cryptos: Object): Promise<Data.Root> => requests.post('home', cryptos),
	getAllHistoricalQuote: (cryptos: Object): Promise<Data.Root> => requests.post('all-historical-quote', cryptos),
	getWatchlistCoins: (cryptos: Object): Promise<Data.Root> => requests.post('watchlist-coins', cryptos),
	getCoinCmcRankOnSwitch: (logos: Object): Promise<Object> => requests.post('currencies-on-switch', logos),
	getTrendingDatabase: (logos: Object): Promise<Object> => requests.post('trending', logos),
	getMostVisitedDatabase: (logos: Object): Promise<Object> => requests.post('most-visited', logos),
	getBigestGainerDatabase: (logos: Object): Promise<Object> => requests.post('bigest-gainer', logos),
	getGlobalMetrics: (cryptos: Object): Promise<Object> => requests.post('home', cryptos),
	getCoinByName: (cryptos: Object): Promise<Object> => requests.post('coin-by-name', cryptos),
	getStats: (cryptos: Object): Promise<Object> => requests.post('home', cryptos),
	getHistorical: (cryptos: Object): Promise<Object> => requests.post('home', cryptos),
	getCoinData: (cryptos: Object): Promise<Object> => requests.post('home', cryptos),
	getPortfolio: (cryptos: Object): Promise<Object> => requests.post('home', cryptos),
	getCoinByCmcRank: (cryptos: Object): Promise<Data.Root> => requests.post('coin-by-cmcrank', cryptos),
	getCoinByCategory: (cryptos: Object): Promise<Object> => requests.post('coin-by-category', cryptos),
	getCountCoinByCategory: (cryptos: Object): Promise<Object> => requests.post('count-coin-by-category', cryptos),
	getAllCoinData: (logos: Object): Promise<Object> => requests.post('all-coin-data', logos),
	getHistoricalQuotes: (cryptos: Object): Promise<Object> => requests.post('quotes-historical', cryptos),
	getCoinDescription: (cryptos: Object): Promise<Object> => requests.post('coinDescription', cryptos),
	addOrRemoveToWatchlist: (cryptos: Object): Promise<Object> => requests.post('watchlist', cryptos),
	getWatchListCoinIdByUser: (cryptos: Object): Promise<Object> => requests.post('watchlist-coinid-by-user', cryptos),
	// deleteWatchList: (cryptos: Object): Promise<Object> => requests.post('delete-watchlist', cryptos),
};

export const userCalls = {
	createUser: (user: User.UserType): Promise<User.UserType> => requests.post('signup', user),
	logUser: (user: Object): Promise<User.ServerData> => requests.post('login', user),
	forgotPassword: (user: Object): Promise<Object> => requests.post('forgot-password', user),
	resetPassword: (user: Object): Promise<Object> => requests.post('reset-password', user),
	verifyEmail: (user: Object): Promise<Object> => requests.post('verify', user),
	logoutUser: (user: Object): Promise<Object> => requests.post('logout', user),
}

export const blogCalls = {
	getBlogPosts: () => requests.get('blog-list'),
	getLatestBlogs: () => requests.get('latest-blogs'),
	getBlogCategories: () => requests.get('blog-categories'),
	addBlogPost: (post: Object): Promise<Object> => requests.post('add-post', post),
	getBlogPost: (post: Object): Promise<Object> => requests.post('blog-post', post),
	editBlogPost: (post: Object): Promise<Object> => requests.post('edit-post', post)
}

export const portfolioCalls = {
	// getUserPortfolio: (post: Object): Promise<Object> => requests.post('portfolio', post),
	// addCoinsToDB: (post: Object): Promise<Object> => requests.post('add-coins', post),
	getCoinsOnScroll: (post: Object): Promise<Object> => requests.post('coin-on-scroll', post),

	addToPortfolio: (post: Object): Promise<Object> => requests.post('add-portfolio', post),
	updateHoldings: (post: Object): Promise<Object> => requests.post('update-holdings', post),
	deleteCoin: (post: Object): Promise<Object> => requests.post('delete-coin', post),
	getTransactions: (post: Object): Promise<Object> => requests.post('coin-transactions', post),
	getCurrentCoinPrice: (post: Object): Promise<Object> => requests.post('home', post),
	getCoinOnSearch: (post: Object): Promise<Object> => requests.post('coin-search', post),
}
