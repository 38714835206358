export default function monthDiff(dateFrom, dateTo) {
    const monthsAgo = 'months ago';
    const monthAgo = 'a month ago';
    const daysAgo = 'days ago';
    const dayAgo = 'a day ago';
    const yearsAgo = 'years ago';
    const yearAgo = 'a year ago';
    const hoursAgo = 'hours ago';
    const overYear = 'over a year ago';
    const year = 12;
    
    const month = Math.abs(dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())));

    //calculate years
    if (month === year) {
        return yearAgo; //prints text only
    } if (month > year && month < 24) {
        return overYear; //prints text only
    } if (month > 24) {
        const years = Math.abs(dateTo.getFullYear() - dateFrom.getFullYear())
        return years + '\xa0' + yearsAgo;
    }

    //calculate months
    if (month === 1) {
        return monthAgo; //prints text only
    } if (month > 1) {
        return month + '\xa0' + monthsAgo;
    }

    //calculate days
    if (month === 0) {
        const day = Math.abs(dateTo.getDate() - dateFrom.getDate());
         //calculate hours
        if (day === 0) {
            const hours = Math.abs(dateFrom.getTime() - dateTo.getTime()) / 3600000;
            return Math.trunc(hours) + '\xa0' + hoursAgo
        }
        if (day === 1) {
            return dayAgo; //prints text only
        } else {
            return day + '\xa0' + daysAgo;
        }
    }
}

