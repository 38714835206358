import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import { CryptoCalls } from '../../../api/api';
import numberWithCommas from '../../../helpers/numberCommas';
import convertExponentialToDecimal from '../../../helpers/decimals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faStar as filledStar } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons';
import AreaGraph from './AreaGraph';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SkeletonElement from '../../../helpers/skeletonElement';

interface Props {
  id: any, name: any, percent_change_24h: any, percent_change_7d: any,
  fully_diluted_market_cap: any, market_cap_dominance: any, price: any,
  marketCap: any, volume_24h: any, circulating_supply: any, max_supply: any,
  total_supply: any, coinPage: any, quoteHistorical: any, cryptoIndex: any, symbol: any,
  trending: any, mostVisited: any, gainersLosers: any, index: any, img: any, rank: any;
  isInWatchList: any, cryptos: any, loading: any;
}


const TableWatchlist = ({ id, cryptos, name, symbol, price, percent_change_24h, percent_change_7d, fully_diluted_market_cap,
  market_cap_dominance, marketCap, volume_24h, quoteHistorical, circulating_supply, max_supply, total_supply, coinPage,
  cryptoIndex, trending, mostVisited, gainersLosers, index, loading, img, rank, isInWatchList }: Props) => {

  const urlName = name.replace(/\s/g, '-');
  let history = useHistory();

  //Watchlist for Table
  const [cookie, setCookie] = useState(Cookies.get('cookie'));
  const [watchListInfo, setWatchListInfo] = useState({ userEmail: '', coinId: id });
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (Cookies.get('email') !== undefined) {
      setWatchListInfo((prevState: any) => {
        return {
          ...prevState,
          userEmail: Cookies.get('email'),
          coinId: id
        }
      })
      if (isInWatchList.includes(id)) {
        setClicked(true);
      }
    }
  }, []);

  const handleWatchList = useCallback(() => {
    if (!cookie) {
      alert('Please login to use the watchlist!')
    } else {
      if (clicked) {
        setClicked(false);
        if (window.location.pathname == '/watchlist') {
          history.go(0);
        }
      } else {
        setClicked(true);
      }
      CryptoCalls.addOrRemoveToWatchlist(watchListInfo).then((info: any) => {
        console.log(info, 'watchlist result')
      });
    }
  }, [watchListInfo, clicked])
  return (
    <>
      <tr>
        <td>
          {clicked ?
            <button className="favorite-fill" style={{ border: 'none', background: 'none' }} onClick={handleWatchList}>
              <FontAwesomeIcon icon={filledStar  as IconProp} />
            </button>
            :
            <button className="favorite" style={{ border: 'none', background: 'none' }} onClick={handleWatchList}>
              <FontAwesomeIcon icon={faStar  as IconProp} />
            </button>
          }
        </td>
        <td className="index">{cryptoIndex}</td>
        <td className="cripto">
          <div>
            <span key={index} className="logo">
              <img src={img} alt="logo" />
            </span>
            <Link
              to={{
                pathname: coinPage + urlName.toLowerCase(),
                state: {
                  cryptoIndex,
                  rank,
                  fully_diluted_market_cap,
                  market_cap_dominance,
                  trending,
                  mostVisited,
                  gainersLosers,
                  cryptos,
                  quoteHistorical,
                  id,
                  name,
                  symbol,
                  price,
                  percent_change_24h,
                  percent_change_7d,
                  marketCap,
                  volume_24h,
                  circulating_supply,
                  max_supply,
                  total_supply,
                  isInWatchList
                },
              }}>
              <span>{name}</span>
              <span>{symbol}</span>
            </Link>
          </div>
        </td>
        <td className="price">${convertExponentialToDecimal(price)}</td>
        <td className="24h" style={{ color: percent_change_24h > 0 ? "#16c784" : "#ea3943" }}>
          {percent_change_24h > 0 ? (
            <FontAwesomeIcon icon={faCaretUp  as IconProp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown  as IconProp} />
          )}
          {Math.abs(percent_change_24h.toFixed(2))}%
        </td>
        <td className="7d" style={{ color: percent_change_7d > 0 ? "#16c784" : "#ea3943" }}>
          {percent_change_7d > 0 ? (
            <FontAwesomeIcon icon={faCaretUp  as IconProp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown  as IconProp} />
          )}
          {Math.abs(percent_change_7d.toFixed(2))}%
        </td>
        <td className="market-cup">${numberWithCommas(marketCap.toFixed(0))}</td>
        <td className="volume">${numberWithCommas(volume_24h.toFixed(0))}</td>
        <td className="supply">{numberWithCommas(circulating_supply.toFixed(0))} {symbol} </td>

        <td className="chart">
          <Link
            to={{
              pathname: coinPage + urlName.toLowerCase(),
              state: {
                period: 7,
                cryptoIndex,
                cryptos,
                id,
                name,
                trending,
                mostVisited,
                gainersLosers,
                symbol,
                quoteHistorical,
                price,
                percent_change_24h,
                percent_change_7d,
                marketCap,
                fully_diluted_market_cap,
                market_cap_dominance,
                volume_24h,
                circulating_supply,
                max_supply,
                total_supply,
                isInWatchList
              },
            }}>
            <AreaGraph quoteHistorical={quoteHistorical}/>
          </Link>
        </td>
      </tr>
    </>
  )
}

export default TableWatchlist;