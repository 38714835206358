import * as React from 'react';
import convertExponentialToDecimal from '../../../helpers/decimals';

const Transactions = (props: any) => {
    const transactions = props.props;
    
    return (
        <div className='assets-data'>
          <table>
              <thead>
                <tr>
                  <th>Total Spent</th>
                  <th>Buy Price</th>
                  <th>Date</th>
                </tr>
              </thead>

              <tbody>
                {transactions !== undefined && Object.values(transactions).map((line: any, index: any) => {
                  const { totalSpent, quantity, date, buyPrice, symbol } = line;
                  var date1 = new Date(date).toLocaleString("uk-Uk"); 
                  return (
                    <tr key={index}>
                      <td>${convertExponentialToDecimal(Number(totalSpent))} <p>{quantity} {symbol}</p></td>
                      <td>${convertExponentialToDecimal(buyPrice)}</td>
                      <td>{date1}</td>
                    </tr>
                  )
                })}
              </tbody>
          </table>
        </div>
    )
} 

export default Transactions;