import React from 'react';
import { CryptoCalls, portfolioCalls } from '../../api/api';
import { useState, useCallback, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import convertExponentialToDecimal from '../../helpers/decimals'
import "react-datepicker/dist/react-datepicker.css";
import PortfolioTable from './components/PortfolioTable';
import PortfolioTableHead from './components/PortfolioTableHead';
import { useHistory } from "react-router-dom";
import { AllocationSlider } from "../../pages/currencies/slider";
import Transactions from './components/transactions';
import CoinsBox from './components/CoinsBox';
import RemoveConfirmation from './components/removeConfirmation';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const iconStyle = {
  fontSize: '20px',
  margin: '0px 10px 0px 10px'
}

const Portfolio = () => {
  let history = useHistory();

  const [TypingTimeout, setTypingTimeout] = useState<any>();
  const [fetch, setFetch] = useState(true);
  const [showSell, setShowSell] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [removeCoin, setRemoveCoin] = useState(false);
  const [remove, setRemove] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [getTransactions, setGetTransactions] = useState({id: null, symbol: ''});
  const [showTransactions, setShowTransactions] = useState(false);

  const [amount, setAmount] = useState(Number);
  const [TotalProfit,setTotalProfit] = useState(Number);
  const [coins, setCoins] = useState(Object);
  const [transactions, setTransactions] = useState();

  const [cryptos, setCryptos] = useState(Object);

  const [showAddTransaction, setShowAddTransaction] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState<any[]>([]); //delete this after deleting the props sent
  
  //values for api
  const [values, setValues] = useState({email: Cookies.get("email"), coinId: '', quantity: '', currentPrice: '', amount: 0, name: '', symbol: '', logo: '', date: new Date()});
  const [getCoins] = useState({type: 'portfolio', email: Cookies.get("email")});
  const [valueUpdate, setValueUpdate] = useState({email: Cookies.get("email"), coinId: '', amount: 0});
  const [valueDeleteCoin] = useState({email: Cookies.get("email"), coinId: ''});

  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState(Array);

  const [cryptoAmount, setCryptoAmount] = useState(10);

  const loader = useRef(null);

  const getAllCoins = useCallback(() => {
    if (fetch) {
      portfolioCalls.getCoinsOnScroll({cryptoAmount}).then((info: any) => {
        // console.log(fetch, 'getAllCoins')
        setCryptos(info.coins);
      })
    }
  }, [cryptoAmount, fetch])

  const getCoinOnSearch = async(name: string) => {
    // const empty = selectedCoin.splice(0, selectedCoin.length);
    // if (selectedCoin) {
    //   setSelectedCoin(empty);
    // }
    console.log(name, 'name')
    return portfolioCalls.getCoinOnSearch({name: name})
    .then((info: any) => {
      //setSelectedCoin(info.data);
      return info.data;
    }).catch((err) => {
      if (err.response.status === 404) {
        alert(err.response.data.message);
      }
    });
  }

  const getUserPortfolio = useCallback(() => {
    CryptoCalls.getPortfolio(getCoins).then((info: any) => {
      //get name, price, percent24 and add to coins object
      let calculateHoldings = Object.values(info.data.data.data).map(function(a: any) {
        return info.portfolio.holdings.some(function(b: any) {
          if (a.id === b.coinId) {
             const items = info.portfolio.holdings.map((obj: any) => {
              return {
                id: obj.coinId,
                symbol: obj.symbol,
                name: Object.values(info.data.data.data).filter(({ id }: any) => id === obj.coinId).map((l: any) => l.name).toString(),
                totalSpent: obj.totalSpent,
                buyPrice: obj.buyPrice,
                amount: obj.amount,
                calcProfitLoss: obj.amount - obj.totalSpent,
                percentage: ((obj.amount - obj.totalSpent) / obj.totalSpent) * 100,
                quantity: obj.quantity,
                logo: obj.logo,
                price: Object.values(info.data.data.data).filter(({ id }: any) => id === obj.coinId).map((l: any) => l.quote.USD.price).toString(),
                percent24: Object.values(info.data.data.data).filter(({ id }: any) => id === obj.coinId).map((l: any) => l.quote.USD.percent_change_24h).toString(),
              }
            });
            setCoins(items);
            //update holdings amount in db:
            const coinPrice = a.quote.USD.price;
            const quantity = b.quantity;
            const id = b.coinId;
            const newAmount = Number(quantity) * Number(coinPrice);
            setValueUpdate({...valueUpdate, coinId: id, amount: newAmount});
          }
        })
      });
      setTotalProfit(info.portfolio.totalProfit);
      setAmount(info.portfolio.totalHoldings);
    })
  }, [getCoins])

  const addPortfolio = useCallback(() => {
    if (values.quantity !== '' && values.amount !== 0) {
      portfolioCalls.addToPortfolio(values).then((info) => {
        history.go(0);
      })
    } else {
      setErrorMsg('Please add a quantity');
    }
  }, [values])

  const updateHoldings = useCallback(() => {
    portfolioCalls.updateHoldings(valueUpdate).then((info) => {
      setUpdated(true);
      setTimeout(function(){ 
        setUpdated(false);
      }, 3000);
    })
  }, [valueUpdate])

  useEffect(() => {
    var options = { //https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      // console.log(loader.current, 'loading current')
      observer.observe(loader.current)
    }
  }, []);

  const handleObserver = (entities: any) => {
    const target = entities[0];
    // console.log(target, 'handleObserver')
    if (target.isIntersecting) {   
      setCryptoAmount((cryptoAmount) => cryptoAmount + 10)
    }
  }

  useEffect(() => {
    getAllCoins();
  }, [getAllCoins])  

  useEffect(() => {
    getUserPortfolio();
  }, [getUserPortfolio])

  useEffect(() => {
    updateHoldings();
  }, [updateHoldings])

  const addTransaction = useCallback(async(id: number, coinName: string, coinSymbol: string, logo: string, type: string) => {
    // const coin = type === 'filtered' ? 
    // Object.values(filteredResults).filter((item: any) => item.name === coinName) 
    // : 
    // Object.values(cryptos).filter((item: any) => item.name === coinName);
    
    const data = await getCurrentCoinPrice(id);
    if (data) {
      //setSelectedCoin(coin);
      const price = data.map((item) => item.price).toString();
      setValues({...values, currentPrice: price, coinId: id.toString(), symbol: coinSymbol, name: coinName, logo: logo});
      !showAddTransaction ? setShowAddTransaction(true) : setShowAddTransaction(false)
    }
  }, []);
 
  const getCurrentCoinPrice = async(coinId: any) => { //returns price on coin click
      let data;
      
      return portfolioCalls.getCurrentCoinPrice({type: "price-specific", id: coinId}).then((info: any) => {
        data = Object.values(info.data.data.data).map((item: any) => ({
            id: item.id,
            price: item.quote.USD.price, 
            percent24: item.quote.USD.percent_change_24h
          }
        ))
        return data;
      })
  } 

  const deleteCoin = useCallback((coinId) => {
    if (coinId) {
      portfolioCalls.deleteCoin({coinId: coinId, email: valueDeleteCoin.email}).then((info) => {
        setDeleteId(null);
        setRemoveCoin(false);
        history.go(0);
      })
    }
  }, []);

  const fetchTransactions = (coinId: number, coinSymbol: string) => {
    portfolioCalls.getTransactions({coinId: coinId, email: valueDeleteCoin.email}).then((info: any) => {
      const newArrayWithSymbol = info.data.map((v: any) => ({...v, symbol: coinSymbol}))
      setTransactions(newArrayWithSymbol);
    })
  }

  useEffect(() => {
    if (getTransactions.id) {
      fetchTransactions(getTransactions.id, getTransactions.symbol)
    }
  }, [getTransactions.id])

  useEffect(() => {
    if (deleteId && remove) {
      deleteCoin(deleteId);
    }
  }, [deleteId, remove])

  useEffect(() => {
    const inputValue = Number(values.quantity) * Number(values.currentPrice); 
    
      if (!showSell) {
        setValues({...values, amount: inputValue})
      } else {
        setValues({...values, amount: -inputValue})
      }
  }, [showSell, values.quantity, values.currentPrice])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
        ...values,
        [e.target.name]: e.target.value,
    });
  };

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue);
      if (searchValue !== '') {
        setFetch(false); //prevent fetching when searching
        const filteredData = cryptos.filter((item: any) => {
          return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase());
        })
        if (filteredData.length === 0) {
          if (TypingTimeout) {
            clearTimeout(TypingTimeout);
          }

          const timeout = setTimeout(async function(){ 
            const coinOnSearch = await getCoinOnSearch(searchValue);
            console.log(coinOnSearch, 'coinOnSearch')
            setFilteredResults(coinOnSearch);
          }, 3000);

          setTypingTimeout(timeout);
        } else {
          setFilteredResults(filteredData);
        }
      }
      else {
        setFetch(true);
        setFilteredResults(cryptos);
      }
  } 

    return (  
      <div className='portfolio-page'>
        <div className="breadcrumbs">
          <span><Link className='link' to='/'>Home</Link></span><FontAwesomeIcon icon={faChevronRight  as IconProp} style={iconStyle}/><span className='heading'>Portfolio</span>
        </div>

        <div className='portfolio-content'>
          
          {cryptos && filteredResults && 
            <CoinsBox props={{ showAddTransaction, setShowAddTransaction, searchInput, filteredResults, addTransaction, cryptos, loader, searchItems, 
              selectedCoin, setShowSell, values, setValues, showSell, addPortfolio, errorMsg, handleChange }}/> 
          }

          {/* portfolio part */}
          <div className='portfolio-baner'>
            <div className='price'>
              <p>Current balance</p>
              <div className='row-right'>
                <div>
                  <p className='amount'>${amount !== 0 ? convertExponentialToDecimal(Number(amount)) : '0.00'}</p> 
                </div>
                <div >
                  <p className='account'>{TotalProfit > 0 ? '+' + '$' + convertExponentialToDecimal(Number(TotalProfit)) : '-' + '$' + convertExponentialToDecimal(Number(Math.abs(TotalProfit)))}</p>
                </div>
                </div>
            </div>

            <div className='asset'>
              <h4>Your Assets</h4>
            </div>
            <div className='table-container'>
              <table className='table'>
                <PortfolioTableHead />
                <tbody>
                  {coins !== undefined && Object.values(coins).map((line: any, index: any) => {
                      const { amount, buyPrice, quantity, totalSpent, price, symbol, percent24, logo, id, name, calcProfitLoss, percentage } = line;
                      return (
                        <tr key={index}>
                          <PortfolioTable props={{name, price, percent24, amount, logo, buyPrice, calcProfitLoss, percentage,
                            quantity, symbol, totalSpent, id, addTransaction, setRemoveCoin, setDeleteId, setGetTransactions, setShowTransactions,
                            showTransactions}}/> 
                        </tr>
                      )
                  })}
                </tbody>
              </table>
              {showTransactions && transactions !== undefined &&
              <Transactions props={transactions} />
              }
              <RemoveConfirmation props={{removeCoin, setRemove, setRemoveCoin}} />
            </div>
            
            <div className='allocation'>
              <h4>Allocation</h4>
              {coins !== undefined && 
                <AllocationSlider props={{coins, amount}} />
              }
            </div>

          </div>
        </div>
            

      </div>
    );
}

export default Portfolio;