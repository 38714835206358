import * as React from 'react';
import { Link } from 'react-router-dom';
import SpotLightTable from './SpotLightTable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const MostVisitedSpotlight = (props: any) => {
    const { mostVisited, metadata, setActiveId, setActiveButton, trending, gainersLosers } = props.props;

    const moreButton = {
      border: 'none',
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      color: '#6614E1'
    }

    const fontWeight = {
      fontWeight: 700
    }

    return (
      <div style={{padding: "10px",border: "1px solid rgb(239, 242, 245)",borderRadius: "8px",boxShadow: '2px 5px 15px #00000026'}}>
        <div style={{display: "flex",justifyContent: "space-between",alignItems: "center",padding: "8px",}}>
          <div style={{display: 'flex'}}>
            <img style={{width: '25px', height: '25px',marginRight: '5px'}} 
              src="https://s2.coinmarketcap.com/static/cloud/img/AddIcon.png?_=b04527d"/>
            <h5 style={fontWeight}>Most Visited</h5>
          </div>
          <button onClick={(() => {setActiveButton(4); setActiveId(4)})} style={moreButton}>
            See more <FontAwesomeIcon icon={faAngleRight  as IconProp} style={{marginLeft: '8px',marginTop: '2px'}}/>
          </button>
        </div>

        <div style={{border: "1px solid rgb(239, 242, 245)", borderLeft: 'none', borderRight: 'none'}}>
          <thead>
            <tr style={{padding: '8px', display: 'flex', width: '400px', fontSize: '14px'}}>
              <th style={{width: '40px'}}>#</th>
              <th style={{width: '150px'}}>Name</th>
              <th style={{width: '150px',textAlign: 'right'}}>Price</th>
              <th style={{width: '120px',textAlign: 'right'}}>24h</th>
            </tr>
          </thead>
        </div>

        {mostVisited !== undefined && mostVisited !== null && Object.values(mostVisited).slice(0, 10).map((line: any, index: any) => {
          const id = line.id;
          const rank = line.cmcRank;
          const name = line.name;
          const symbol = line.symbol;
          const price = JSON.parse(line.quote).USD.price;
          const percent_change_24h = JSON.parse(line.quote).USD.percent_change_24h;
          const percent_change_7d = JSON.parse(line.quote).USD.percent_change_7d;
          const fully_diluted_market_cap = JSON.parse(line.quote).USD.fully_diluted_market_cap;
          const market_cap_dominance = JSON.parse(line.quote).USD.market_cap_dominance;
          const marketCap = JSON.parse(line.quote).USD.market_cap;
          const volume_24h = JSON.parse(line.quote).USD.volume_24h;
          const circulating_supply = line.circulating_supply;
          const max_supply = line.max_supply;
          const total_supply = line.total_supply;

              return (
                <div key={index}>
                  {mostVisited !== undefined &&
                    Object.values(mostVisited).map((line: any, i: any) => {
                      const metaId = line.id;
                      if (id === metaId) {
                        const img = line.logo;
                        const props = {
                          id,
                          index,
                          rank,
                          symbol,
                          img,
                          name,
                          price,
                          percent_change_24h,
                          metadata,
                          trending,
                          mostVisited,
                          gainersLosers,
                          percent_change_7d,
                          marketCap,
                          volume_24h,
                          circulating_supply,
                          max_supply,
                          total_supply,
                          fully_diluted_market_cap,
                          market_cap_dominance,
                        };

                        return (
                          <div key={index}>
                            <SpotLightTable {...props} />
                          </div>
                        );
                      }
                    })}
                </div>
              );
            })}
      </div>
    );
}

export default MostVisitedSpotlight;