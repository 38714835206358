import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import phoneImg from '../../assets/Images/phone.png'
import person from '../../assets/Images/person.png'
import people from '../../assets/Images/people.png'
import facebook from '../../assets/Images/facebook.png'
import instagramLogo from '../../assets/Images/instagram.png'
import twitter from '../../assets/Images/twitter.png'
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const About = () => {
  return (
    <div className="about-wraper">
      <div className="about-menu">
        <p className="home">Home</p>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="home-part" />
        <p className="about">About</p>
      </div>

      <div className="first-section">
        <div className="first-part">
          <h1 className="heading" id="about-us">ABOUT US</h1>
          <p className="paragraph">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
            sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
            ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
            sea takimata sanctus est Lorem ipsum dolor sit amet. <br /><br /> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
          </p>
          <button className="button-about">
            CONTACT US</button>
        </div>
        <div className="second-part">
          <img src={phoneImg} className="image" />
        </div>
      </div>

      <div className="second-section">
        <div className="wraper">
          <h1 className="heading">
            OUR TEAM
          </h1>
          <div><img src={people} className="image" /></div>
          <div className="text-wraper">
            <p className="text">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            </p>
          </div>
        </div>

        <div className="third-section">
          <div className="wraper">
            <div className="first-part">
              <div className="div-wraper">
                <img src={person} className="image" />
              </div>
            </div>
            <div className="second-part">
              <h5 className="heading">John Doe, 26</h5>
              <p className="paragraph">johndoe@gmail.com</p>
              <div className="image-wraper">
                <img src={facebook} className="image-general" />
                <img src={instagramLogo} className="image-general" />
                <img src={twitter} className="twitter" />
              </div>
            </div>
          </div>

          <div className="wraper">
            <div className="first-part">
              <div className="div-wraper">
                <img src={person} className="image" />
              </div>
            </div>
            <div className="second-part">
              <h5 className="heading">John Doe, 26</h5>
              <p className="paragraph">johndoe@gmail.com</p>
              <div className="image-wraper">
                <img src={facebook} className="image-general" />
                <img src={instagramLogo} className="image-general" />
                <img src={twitter} className="twitter" />
              </div>
            </div>
          </div>

          <div className="wraper">
            <div className="first-part">
              <div className="div-wraper">
                <img src={person} className="image" />
              </div>
            </div>
            <div className="second-part">
              <h5 className="heading">John Doe, 26</h5>
              <p className="paragraph">johndoe@gmail.com</p>
              <div className="image-wraper">
                <img src={facebook} className="image-general" />
                <img src={instagramLogo} className="image-general" />
                <img src={twitter} className="twitter" />
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default About;
