import './assets/theme.scss';
import './App.css';
import { useState, useEffect } from 'react';
import { CryptoCalls } from "./api/api";
import Header from './components/shared/header';
import Footer from './components/shared/footer';
import Home from './pages/home/home';
import About from './pages/about/about';
import Blog from './pages/blog/blog';
import Contact from './pages/contact/contact';
import Portfolio from './pages/portfolio/portfolio';
import SignUp from './pages/signup/SignUp';
import Login from './pages/login/Login';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import ResetPassword from './pages/reset-password/resetPassword';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CoinPage from './pages/currencies/CoinPage';
import MainTrending from './pages/Trending/MainTrending';
import HistoricalData from './pages/currencies/components/HistoricalData';
import TermsConditions from './pages/terms-conditions/terms-conditions';
import PrivacyPolicy from './pages/privacy-policy/privacy-policy';
import VerifyEmail from './pages/verify-email/VerifyEmail';
import Watchlist from './pages/watchlist/Watchlist';
import BlogPost from './pages/blog/components/BlogPost';
import AdminPanel from './pages/admin/AdminPanel';
import Categories from './pages/categories/Categories';
import FAQ from './pages/home/Components/FAQ';
import FrequentlyAskedQuestions from './pages/home/Components/FrequentlyAskedQuestions';

const App = () => {
  const [totalCurrencies, setTotalCurrencies] = useState();
  const [activeExchanges, setActiveExchanges] = useState();
  const [marketCap, setMarketCap] = useState();
  const [volume24, setVolume24] = useState();
  const [btcDominance, setBtcDominance] = useState();
  const [btcDominancePercentChange, setBtcDominancePercentChange] = useState();
  const [ethDominance, setEthDominance] = useState();
  const [marketCapYesterday, setMarketCapYesterday] = useState();
  const [totalVolume24, setTotalVolume24] = useState();
  const [totalVolume24Yesterday, setTotalVolume24Yesterday] = useState();
  const [defiVolume24, setDefiVolume24] = useState();
  const [stableCoinVolume24, setStableCoinVolume24] = useState();

  const [value, setValue] = useState({
    type: "global-metrics",
  })

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      let getData = await CryptoCalls.getGlobalMetrics(value).then((info: any) => {
        const result = info.data.data.data;
        if (isMounted) {
          setTotalCurrencies(result.total_cryptocurrencies);
          setActiveExchanges(result.active_exchanges);
          setMarketCap(result.quote.USD.total_market_cap);
          setVolume24(result.quote.USD.total_volume_24h);
          setBtcDominance(result.btc_dominance);
          setEthDominance(result.eth_dominance);
          setMarketCapYesterday(result.quote.USD.total_market_cap_yesterday_percentage_change);
          setTotalVolume24(result.quote.USD.total_volume_24h);
          setTotalVolume24Yesterday(result.quote.USD.total_volume_24h_yesterday_percentage_change);
          setDefiVolume24(result.defi_volume_24h);
          setStableCoinVolume24(result.stablecoin_volume_24h);
          setBtcDominancePercentChange(result.btc_dominance_24h_percentage_change);
        }
      });
    }
      fetchData();
      return () => { isMounted = false };
  }, [])

  const GlobalStats = { totalCurrencies, activeExchanges, marketCap, volume24, btcDominance, ethDominance, marketCapYesterday, totalVolume24, 
    totalVolume24Yesterday, defiVolume24, stableCoinVolume24, btcDominancePercentChange };

  return (
    <Router>
      <div className="App">
        <Header props={GlobalStats}/>
        <div className="content">
          <Switch>
            <Route exact path="/">
              {GlobalStats.btcDominancePercentChange != undefined &&
              <Home props={GlobalStats}/>}
            </Route>
            <Route path="/terms-and-conditions">
              <TermsConditions />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/blog/:title">
              <BlogPost />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/portfolio">
              <Portfolio /> 
            </Route>
            <Route path="/watchlist">
              <Watchlist />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            <Route path="/verify-email/:token/:id">
              <VerifyEmail />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/trending-cryptocurrencies">
              <MainTrending />
            </Route>
            <Route path="/reset-password/:token/:id">
              <ResetPassword />
            </Route>
            <Route path="/currencies/:name/historical-data">
              <HistoricalData />
            </Route>
            <Route path="/currencies/:name">
              <CoinPage />
            </Route>
            <Route path="/admin-panel">
              <AdminPanel />
            </Route>
            <Route path="/categories">
              <Categories />
            </Route>
            <Route path="/faq">
              <FrequentlyAskedQuestions />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
