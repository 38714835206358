import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const NavTrending = (props: any) => {
  const { setActiveId, setActiveButton, activeButton } = props.props;

  const items = [
    { name: 'Spotlight', id: 1 },
    { name: 'Gainers and Losers', id: 2 },
    { name: 'Trending', id: 3 },
    { name: 'Most Visited', id: 4 },
  ];

  return (
    <div style={{backgroundColor: 'white'}}>
      <Link style={{border: 'none', background: 'none', textDecoration: 'none', color: "purple"}} to="/watchlist"><FontAwesomeIcon icon={faStar  as IconProp} /> Watchlist</Link>

      {items.map((item, i) => (
        <button key={i} style={{border: 'none', background: 'none', fontWeight: activeButton === item.id ? 900 : 300, color: "purple"}} onClick={(() => {setActiveId(item.id); setActiveButton(item.id)})}>
          {item.name}
        </button>
      ))}
    </div>
  );
};

export default NavTrending;