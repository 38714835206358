import * as React from 'react';

const TableHead = () => {
    return (
          <thead>
            <tr>
              <th></th>
              <th scope="col">#</th>
              <th>Name</th>
              <th scope="col">Price</th>
              <th scope="col">24h %</th>
              <th scope="col">7d %</th>
              <th scope="col">Market Cap</th>
              <th scope="col">Volume(24h)</th>
              <th scope="col">Circulating Supply</th>
              <th scope="col">Last 7 Days</th>
            </tr>
          </thead>
    )
}

export default TableHead;