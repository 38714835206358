import * as React from 'react';
import { useState } from 'react';
import { useLocation } from "react-router";
import NavTrending from './NavTrending';
import TrendingCurrencies from './Nav Components/Trending/TrendingCurrencies';
import MostVisitedCurrencies from './Nav Components/MostVisited/MostVisitedCurrencies';
import GainersLosers from './Nav Components/Gainers/GainersLosers';
import Spotlight from './Nav Components/Spotlight/Spotlight';

const MainTrending = () => {
  const location: any = useLocation();

  const { trending, mostVisited, gainersLosers, metadata } = location.state;

  const [activeId, setActiveId] = useState(1);
  const [activeButton, setActiveButton] = useState(1);

  const SpotlightProps = { trending, mostVisited, gainersLosers, metadata, setActiveId, setActiveButton }
  const GainersLosersProps = { gainersLosers, metadata }
  const TrendingCurrenciesProps = { trending, metadata }
  const MostVisitedCurrenciesProps = { mostVisited, metadata }

  const components = [
    { name: "Spotlight", id: 1, component: <Spotlight props={SpotlightProps}/>},
    { name: "Gainers and Losers", id: 2, component: <GainersLosers props={GainersLosersProps}/>},
    { name: "Trending", id: 3, component: <TrendingCurrencies props={TrendingCurrenciesProps}/>},
    { name: "Most Visited", id: 4, component: <MostVisitedCurrencies props={MostVisitedCurrenciesProps}/>}
  ];

  const showComponents = components.map(component => {
    if (component.id === activeId) {
      return <div>{component.component}</div>
    }
  })

  return (
    <>
      <NavTrending props={{setActiveId, setActiveButton, activeButton}}/> 
      {showComponents}
    </>
  );
};

export default MainTrending;