import * as React from 'react';
import AddPost from '../blog/components/addPost';

const AdminPanel = () => {
    return (
        <>
            <AddPost />
        </>
    )
}

export default AdminPanel;