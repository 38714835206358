import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const BiggestGainers = (trendingProps: any) => {
  const { trending, mostVisited, gainersLosers, allCoinData, stats, isInWatchList } = trendingProps;

  return (
    <div className="items-holder">
      {gainersLosers !== undefined && gainersLosers !== null && Object.values(gainersLosers).slice(0, 6).map((line: any, index: any) => {
        const id = line.id;
        const rank = line.cmcRank;
        const name = line.name;
        const symbol = line.symbol;
        const price = JSON.parse(line.quote).USD.price;
        const percent_change_24h = JSON.parse(line.quote).USD.percent_change_24h;
        const percent_change_7d = JSON.parse(line.quote).USD.percent_change_7d;
        const fully_diluted_market_cap = JSON.parse(line.quote).USD.fully_diluted_market_cap;
        const market_cap_dominance = JSON.parse(line.quote).USD.market_cap_dominance;
        const marketCap = JSON.parse(line.quote).USD.market_cap;
        const volume_24h = JSON.parse(line.quote).USD.volume_24h;
        const circulating_supply = line.circulatingSupply;
        const max_supply = line.maxSupply;
        const total_supply = line.totalSupply;
        const coinPage = "/currencies/";
        const urlName = name.replace(/\s/g, '-');

            return (
              gainersLosers !== undefined &&
                Object.values(gainersLosers).map((line: any, index: any) => {
                  const metaId = line.id;
                  if (id === metaId) {
                    const img = line.logo;
                      return (
                      <div key={index} className="item">
                        <div className="item-logo">
                          <img src={img} alt="logo" />
                        </div>

                        <div className="holder">
                          <Link to={{
                            pathname: coinPage + urlName.toLowerCase(),
                            state: {
                              allCoinData, 
                              trending,
                              mostVisited,
                              gainersLosers,
                              //stats, 
                              rank,
                              id,
                              name,
                              symbol,
                              price,
                              percent_change_24h,
                              percent_change_7d,
                              fully_diluted_market_cap,
                              marketCap,
                              volume_24h,
                              circulating_supply,
                              max_supply,
                              total_supply,
                              market_cap_dominance,
                              isInWatchList
                            },
                          }}>
                            <p className="item-title">{name}</p>
                          </Link>
                          <p className="item-symbol">{symbol}</p>
                        </div>

                        <div className="item-percent">
                          <p>
                            {percent_change_24h > 0 ? (
                              <FontAwesomeIcon icon={faCaretUp  as IconProp} />
                            ) : (
                              <FontAwesomeIcon icon={faCaretDown  as IconProp} />
                            )}
                            {Math.abs(percent_change_24h.toFixed(2))}%
                          </p>
                        </div>

                      </div>
                    );
                  }
                })
            );
      })}
    </div>
  );
};

export default BiggestGainers;