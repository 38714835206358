import * as React from 'react';
import SinglePost from './SinglePost';
import { baseUrl } from '../../../config.js';


const Posts = ({ posts, selectedCategory, showCategory }: any) => {
  let filtered;
  showCategory ? filtered = posts.filter((i: any) => i.categoryId === selectedCategory.id) : filtered = posts;

  return (
    <div className="blog-post-listing">
      {filtered !== undefined && filtered.map((line: any, index: any) => {
        const { title, description, content, featured_image, permalink, createdAt } = line;
        const image = baseUrl + 'blog/' + featured_image;
        var date = new Date(createdAt).toLocaleString();

        return (
          <div key={index} className="blog-post">
            <SinglePost props={{ title, description, content, image, permalink, date }} />
          </div>
        )
      })}
    </div>
  );
}

export default Posts;