import * as React from "react";
import Graph from "./Graph";
import CoinStats from "./CoinStats";
import ChartNav from "./ChartNav";
import TrendingTabs from "../../home/Components/TrendingTabs";
import Converter from "./Converter";
import AboutCoin from "./AboutCoin";
import Like from "./Like";
import Analysis from "./Analysis";
import WatchedCoins from "./WatchedCoins";

const Overview = (props: any) => {
  let metadata
  let id: any;
  let name: any;
  let rank
  let stats
  let symbol: any
  let price: any
  let marketCap
  let volume_24h
  let circulating_supply
  let fully_diluted_market_cap
  let max_supply
  let total_supply
  let market_cap_dominance
  let img
  let period
  let isInWatchList
  let description
  let trending
  let mostVisited
  let gainersLosers
  
  // const { metadata, id, name, rank, stats, symbol, price, marketCap, volume_24h, circulating_supply, max_supply, 
  //   total_supply, img, period, mostVisited, gainersLosers, isInWatchList, description, market_cap_dominance = 1124124124, 
  //   fully_diluted_market_cap } = props.props.location

  if (props.props.coinFromUrl === false) {
    metadata = props.props.location.metadata
    id = props.props.location.id
    name = props.props.location.name
    rank = props.props.location.rank
    stats = props.props.location.stats
    symbol = props.props.location.symbol
    price = props.props.location.price
    marketCap = props.props.location.marketCap
    volume_24h = props.props.location.volume_24h
    circulating_supply = props.props.location.circulating_supply
    fully_diluted_market_cap = props.props.location.fully_diluted_market_cap
    max_supply = props.props.location.max_supply
    total_supply = props.props.location.total_supply
    market_cap_dominance = props.props.location.market_cap_dominance
    img = props.props.location.img
    period = props.props.location.period
    trending = props.props.location.trending
    mostVisited = props.props.location.mostVisited
    gainersLosers = props.props.location.gainersLosers
    isInWatchList = props.props.isInWatchList
    description = props.props.description
  } else if (props.props.coinFromUrl === true) {
    metadata = props.props.location.metadata
    id = props.props.location.id
    name = props.props.location.name
    rank = props.props.location.rank
    stats = props.props.location.stats
    symbol = props.props.location.symbol
    price = props.props.location.price
    marketCap = props.props.location.marketCap
    volume_24h = props.props.location.volume_24h
    circulating_supply = props.props.location.circulating_supply
    fully_diluted_market_cap = props.props.location.fully_diluted_market_cap
    max_supply = props.props.location.max_supply
    total_supply = props.props.location.total_supply
    market_cap_dominance = 123125125125
    img = props.props.location.img
    period = props.props.location.period
    trending = props.props.location.trending
    mostVisited = props.props.location.mostVisited
    gainersLosers = props.props.location.gainersLosers
    isInWatchList = props.props.isInWatchList
    description = props.props.description
  }

  const TrendingTabsProps = {
    metadata: metadata,
    trending: trending,
    mostVisited,
    gainersLosers,
    stats,
    isInWatchList
  };

  return (
    <div className="coin-section">
      <div className="first">
        <div className="title"><p>{name} to USD Chart</p></div>
        <ChartNav period={period} />
        <Graph id={id}/>
      </div>
      <div className="sub-section">
        <div className="about-coin">
          <AboutCoin props={{ description }} />
          <Like props={{ name }} />
          <Analysis props={{ name }} />

        </div>
        <div className="coin-sidebar">
          {metadata !== undefined &&
            Object.values(metadata).map((line: any, index: any) => {
              const metaId = line.id;
              if (id === metaId) {
                const { logo: img } = line;
                return (
                  <div key={index}>
                    <Converter props={{ img, price, symbol, name }} />
                  </div>
                );
              }
            })}

          <CoinStats
            props={{
              name,
              symbol,
              stats,
              id,
              circulating_supply,
              total_supply,
              max_supply,
              price,
              volume_24h,
              market_cap_dominance,
              rank,
              marketCap,
              fully_diluted_market_cap,
            }}
          />
          <TrendingTabs props={TrendingTabsProps} />
        </div>
        <WatchedCoins />
      </div>
    </div>

  );
};

export default Overview;
