import * as React from 'react';
import convertExponentialToDecimal from '../../../helpers/decimals';
import FormInput from '../../../components/shared/FormInput';
import Button from '../../../components/shared/button';
import DatePicker from "react-datepicker";

const AddTransaction = (props: any) => {
    const { setShowSell, values, setValues, showSell, addPortfolio, errorMsg, handleChange } = props.props;

    return (
        <>
            <div className='coin'>
            {/* {selectedCoin !== undefined && Object.values(selectedCoin).map((line: any, index: any) => {
              const { id, name, symbol, logo } = line;
              return (
                <div key={index}>
                  <span className="logo">
                    <img src={logo} alt="logo" width={'40px'} />
                  </span>
                  <span style={{margin: '5px'}}>{name}</span>
                  <span>{symbol}</span>    
                  <div style={{display: 'flex'}}>
                    <button onClick = {() => setShowSell(false)}>Buy</button>
                    <button onClick = {() => setShowSell(true)}>Sell</button>
                  </div>
                  </div>
                  )
                })}  */}

                <div>
                  <span className="logo">
                    <img src={values.logo} alt="logo" width={'40px'} />
                  </span>
                  <span style={{margin: '5px'}}>{values.name}</span>
                  <span>{values.symbol}</span>    
                  <div className='button' style={{display: 'flex'}}>
                    <button className='buy' onClick = {() => setShowSell(false)}>Buy</button>
                    <button className='sell' onClick = {() => setShowSell(true)}>Sell</button>
                  </div>
                </div>
                
                <form className="sign-up-form">
                  <FormInput
                      type="number"
                      value={values.quantity}
                      label="Quantity"
                      name="quantity"
                      onChange={handleChange}
                  />
                  <input 
                    type="number"
                    defaultValue={Number(values.currentPrice).toFixed(2)}
                    name="currentPrice"
                    //add label
                    onChange={handleChange}
                  />
                  <DatePicker 
                    selected={values.date}
                    //customInput={<ExampleCustomInput />}
                    showTimeSelect 
                    onChange={(date: any) => setValues({...values, date: date})} 
                  /> 
                  {/* https://reactdatepicker.com/ */}

                  <p>
                    {!showSell ? 'Total Spent:' : 'Total Received'} 
                    {values.amount === 0 ? '$0' : '$' + convertExponentialToDecimal(Number(Math.abs(values.amount)))}
                  </p>
                </form>

                  <div className="submit">
                    <Button type="submit" handleClick={addPortfolio} label="Add Transaction" />
                  </div>

                  <div><p>{errorMsg}</p></div>
            </div>
        </>
    )
}

export default AddTransaction;