import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import AddTransaction from './AddTransaction';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const CoinsBox = (props: any) => {
    const { showAddTransaction, setShowAddTransaction, searchInput, filteredResults, addTransaction, cryptos, loader, searchItems,
        selectedCoin, setShowSell, values, setValues, showSell, addPortfolio, errorMsg, handleChange } = props.props;
    return (
        <div className='coinBox'>
            <div className='head'>
                <h3>{!showAddTransaction ? 'Select Coin' : 'Add Transaction'}</h3>
                {!showAddTransaction ?
                    <input placeholder='Search...' onChange={(e) => searchItems(e.target.value)} />
                    :
                    <span onClick={() => (setShowAddTransaction(false))}><FontAwesomeIcon icon={faAngleLeft as IconProp} /></span>
                }
            </div>

            <div className="no-rule">
                {searchInput.length > 0 ? (
                    filteredResults !== undefined && Object.values(filteredResults).map((line: any, index: any) => {
                        const { id, name, symbol, logo } = line;
                        return (
                            !showAddTransaction &&
                            <div key={index} onClick={() => addTransaction(id, name, symbol, logo)}>
                                <span className="logo">
                                    <img src={logo} alt="logo" />
                                </span>
                                <span>{name}</span>
                                <span>{symbol}</span>
                                <span><FontAwesomeIcon icon={faAngleRight as IconProp} /></span>
                            </div>
                        );
                    })
                ) : (
                    cryptos !== undefined && Object.values(cryptos).map((line: any, index: any) => {
                        const { id, name, symbol, logo } = line;
                        return (
                            !showAddTransaction &&
                            <div key={index} onClick={() => addTransaction(id, name, symbol, logo)}>
                                <span className="logo">
                                    <img src={logo} alt="logo" />
                                </span>
                                <span>{name}</span>
                                <span>|</span>
                                <span>{symbol}</span>
                                <span><FontAwesomeIcon icon={faAngleRight as IconProp} /></span>
                            </div>
                        );
                    })
                )}
                <div ref={loader}></div>

                {showAddTransaction &&
                    <AddTransaction props={{ showAddTransaction, selectedCoin, setShowSell, values, setValues, showSell, addPortfolio, errorMsg, handleChange }} />
                }
            </div>
        </div>
    )
}

export default CoinsBox;