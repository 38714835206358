import * as React from 'react';
import TableHead from '../home/Components/TableHead';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const EmptyWatchlist = () => {
    const circleStyle = {
        background: '#F2F5F8',
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        marginTop: '20px'
    }

    return (
        <>
            <table className="box-table">
                <TableHead />
                {/* fake background table here */}
            </table>
            <div>
                <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={circleStyle}>
                            {/* <FontAwesomeIcon icon={faStar}/> */}
                        </div>
                    </div>
                    <h3 style={{textAlign: 'center'}}>Your Watchlist is Empty</h3>
                    <p style={{textAlign: 'center'}}>Start building your watchlist by clicking button below</p>
                </div>
            </div>
        </>
    )
}

export default EmptyWatchlist;