import React, { useEffect, useState } from "react";
import SkeletonElement from "../../../helpers/skeletonElement";
import BoxNav from "./BoxNav";
import BoxTable from "./BoxTable";
import Loading from './Loading'

interface Props {
  cryptos: any;
  coinNumber: any;
  coinByCmcRank: any;
  allData: any;
  categoryCrypto: any;
  trending: any;
  setRecords: any;
  fetchCategory: any;
  mostVisited: any;
  gainersLosers: any;
  itemsPerPage: any;
  loading: boolean;
  isInWatchList: any;
  cookie: any;
  coinName: any;
}

const Box = ({ cryptos, coinByCmcRank, allData, categoryCrypto, trending, coinNumber, setRecords, fetchCategory, mostVisited, gainersLosers, itemsPerPage, loading, isInWatchList, cookie, coinName }: Props) => {
  const BoxNavProps = { setRecords, allData, itemsPerPage, fetchCategory, cookie, coinName };
  const BoxTableProps = { cryptos, coinByCmcRank, allData, categoryCrypto, trending, coinNumber, mostVisited, gainersLosers, loading, isInWatchList, cookie }
  const [wraper, setWraper] = useState(true);
  useEffect(() => {
    if(Object.keys(cryptos).length != 0) {
      setWraper(false)
    }
  }, [wraper]);
  return (
    <>
      <div className="box">
        <BoxNav {...BoxNavProps} />
        {Object.keys(cryptos).length === 0 ? (<SkeletonElement type='coin'/>) : (<BoxTable {...BoxTableProps} />)}
            
      </div>
    </>
  );
};

export default Box;
