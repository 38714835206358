import * as React from "react";
import Trending from "./Trending";
import MostVisited from "./MostVisited";
import BiggestGainers from "./BiggestGainers";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Loading from "./Loading";

const TrendingTabs = (TrendingTabsProps: any) => {
  const { loading, allCoinData, trending, mostVisited, gainersLosers, isInWatchList } = TrendingTabsProps.props;

  const trendingProps = {
    allCoinData: allCoinData,
    trending: trending,
    mostVisited: mostVisited,
    gainersLosers: gainersLosers,
    //stats: stats,
    isInWatchList: isInWatchList,
    loading: loading
  };

  const mostVisitedProps = {
    allCoinData: allCoinData,
    mostVisited: mostVisited,
    trending: trending,
    gainersLosers: gainersLosers,
    //stats: stats,
    isInWatchList: isInWatchList
  };

  const biggestGainersProps = {
    allCoinData: allCoinData,
    gainersLosers: gainersLosers,
    trending: trending,
    mostVisited: mostVisited,
    //stats: stats,
    isInWatchList: isInWatchList
  };

  return (
    <>
      <div className="tab-box">
        <Tabs defaultActiveKey="Trending" id="home-main-tabs">
          <Tab eventKey="Trending" title="Trending">
            <Trending {...trendingProps} />
          </Tab>
          <Tab eventKey="Gainers" title="Biggest Gainers">
            <BiggestGainers {...biggestGainersProps} />
          </Tab>
          <Tab eventKey="Visited" title="Most Visited">
            <MostVisited {...mostVisitedProps} />
          </Tab>
        </Tabs>
        <div className="more">
          <Link
            to={{ pathname: "/trending-cryptocurrencies", state: { trending, gainersLosers, mostVisited, allCoinData } }}>
            <span>More</span><span><FontAwesomeIcon icon={faAngleRight as IconProp} /></span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TrendingTabs;
