import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faLink, faExternalLinkAlt, faSearch, faUserAlt, faNewspaper, faComment, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { useState } from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/scss/bootstrap.scss";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const CoinPageInfo = (infoProps: any) => {
  const { url, tags, description, reddit, twitter, github, chat, whitepaper, explorers } = infoProps;
  return (
    <div className="page-info">
      <Tabs
        defaultActiveKey=""
        transition={false}
        id="noanim-tab-example"
        className=""
      >
        <Tab eventKey="Website" title={<div onClick={() => { window.open(url, '_blank') }}>
          <FontAwesomeIcon icon={faLink as IconProp} />
          <span>{new URL(url).host}</span>
          <FontAwesomeIcon icon={faExternalLinkAlt as IconProp} />
        </div>}>

        </Tab>
        <Tab eventKey="Explorers" title={<div>
          <FontAwesomeIcon icon={faSearch as IconProp} />
          <span>Explorers</span>
          <FontAwesomeIcon icon={faCaretDown as IconProp} />
        </div>}>
          {explorers && explorers.map((explorer: any) =>
            <a href={explorer} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faChevronRight as IconProp} />{new URL(explorer).host}</a>
          )}
        </Tab>
        <Tab eventKey="Community" title={<div>
          <FontAwesomeIcon icon={faUserAlt as IconProp} />

          <span>Community</span>
          <FontAwesomeIcon icon={faCaretDown as IconProp} />
        </div>}>
          {twitter === "" && twitter.map((bitcoin: any) =>
           <a href={bitcoin} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faChevronRight as IconProp} />{new URL(bitcoin).host}</a>
          )}

          {reddit === "" && reddit.map((reddit: any) =>
           <a href={reddit} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faChevronRight as IconProp} />{new URL(reddit).host}</a>
          )}
        </Tab>
        <Tab eventKey="Chat" title={<div>
          <FontAwesomeIcon icon={faComment as IconProp} />
          <span>Chat</span>
          <FontAwesomeIcon icon={faCaretDown as IconProp} />
        </div>}>
          {chat === "" && chat.map((link: any) =>
            <a href={link} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faChevronRight as IconProp} />{new URL(link).host}</a>
          )}
        </Tab>
        <Tab eventKey="Whitepaper" title={<div onClick={() => { window.open(whitepaper.replace('"','').replace('"', '').replace('[', '').replace(']',''), '_blank') }}>
          <FontAwesomeIcon icon={faNewspaper as IconProp} />
          <span>Whitepaper</span>
          <FontAwesomeIcon icon={faExternalLinkAlt as IconProp} />
        </div>}>
        </Tab>
        <Tab eventKey="Source-code" title={<div onClick={() => { window.open(github.replace('"','').replace('"', '').replace('[', '').replace(']',''), '_blank')}}>
          <FontAwesomeIcon icon={faGithub as IconProp} />
          <span>GitHub</span>

        </div>}>

        </Tab>

      </Tabs>


      {/* <ul> */}
      {/* <li>
          <span>Website</span>
          <button onClick={() => { window.open(url, '_blank') }}>
            <FontAwesomeIcon icon={faLink} />
            <span >{new URL(url).host}</span>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </button>
        </li> */}

      {/* <li>
          <span>Explorers</span>
          <button className="ishover"> */}
      {/* onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} */}
      {/* <FontAwesomeIcon icon={faSearch} />
            <span>Explorers</span>
            <FontAwesomeIcon icon={faCaretDown} />
            <div className='isabsolute'> */}
      {/* isHovering && */}
      {/* {explorers.map((explorer: any) =>
                <a href={explorer}>{explorer}</a>
              )}
            </div>
          </button>

        </li> */}

      {/* <li>
          <span>Community</span>
          <button>
            <FontAwesomeIcon icon={faUserAlt} /> */}
      {/* onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} */}
      {/* <span >Community</span>
            <FontAwesomeIcon icon={faCaretDown} />
          </button>
          <span> */}
      {/* isHovering && */}
      {/* {community_bitcoin.map((community_bitcoin: any) => community_reddit.map((community_reddit: any) =>
              <>
                <li>{community_reddit}</li>
                <li>{community_bitcoin}</li>
              </>
            ))}
          </span>
        </li> */}

      {/* <li> */}
      {/* Da se proveri */}
      {/* <span>Whitepaper</span>
          <button>
            <FontAwesomeIcon icon={faNewspaper} />
            <span>Whitepaper</span>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </button>
        </li> */}

      {/* <li>
          <span>Source code</span>
          <button>
            <FontAwesomeIcon icon={faGithub} />
            <span>GitHub</span>
          </button>
        </li>

      </ul> */}
    </div>
  );
}

export default CoinPageInfo;