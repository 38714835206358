import * as React from 'react';
import { useState } from 'react';
import convertExponentialToDecimal from '../../../helpers/decimals';

const Converter = (props: any) => {
    const { img, price, symbol, name } = props.props;

    const [input, setInput] = useState('1');
    const [usdAmount, setUsdAmount] = useState(price);

    const handleValueChange = (e: any) => {
        e.preventDefault();
        setInput(e.target.value);
        const inputValue = e.target.value * price;
        setUsdAmount(inputValue);
    }

    return (
        <><div className="converter">
            <p className="converter-title">{name} to USD Converter</p>
            <div className="converter-holder">
                <div>
                    <div className="crypto-img"><img src={img} alt="crypto-img" /></div>
                    <div className="name">
                        <p>{symbol}</p>
                        <p>{name}</p>
                    </div>
                    <div>
                        <input type="number" min="1" max="9999"
                            value={input} onChange={handleValueChange} pattern="/^-?d+.?d*$/" >
                        </input>
                    </div>
                </div>

                <div>
                    <div className="crypto-img"><img src='https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/USD.svg' alt='USD-img' /></div>
                    <div className="name">
                        <p>USD</p>
                        <p>United States Dollar</p>
                    </div>
                    <div>
                        <input type="text" value={"$" + convertExponentialToDecimal(usdAmount)} pattern="/^-?d+.?d*$/" disabled>
                        </input>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Converter;

