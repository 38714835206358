import * as React from 'react';
import Table from './Table';
import TableWatchlist from './TableWatchlist';
import TableHead from './TableHead';

interface Props {
  cryptos: any;
  trending: any;
  coinNumber: any;
  mostVisited: any;
  gainersLosers: any;
  loading: boolean;
  isInWatchList: any;
}


const BoxTableWatchlist = ({ cryptos, trending, coinNumber, mostVisited, gainersLosers, loading, isInWatchList } : Props) => {

  return (
    <table className="box-table">
      <TableHead />
      <tbody>
        {cryptos !== undefined &&
          Object.values(cryptos).map((line: any, index: any) => {
            const id = line.id;
            const rank = line.cmcRank;
            const name = line.name;
            const symbol = line.symbol;
            const price = JSON.parse(line.quote).USD.price;
            const percent_change_24h = JSON.parse(line.quote).USD.percent_change_24h;
            const percent_change_7d = JSON.parse(line.quote).USD.percent_change_7d;
            const fully_diluted_market_cap = JSON.parse(line.quote).USD.fully_diluted_market_cap;
            const market_cap_dominance = JSON.parse(line.quote).USD.market_cap_dominance;
            const marketCap = JSON.parse(line.quote).USD.market_cap;
            const volume_24h = JSON.parse(line.quote).USD.volume_24h;
            const circulating_supply = line.circulatingSupply;
            const max_supply = line.maxSupply;
            const total_supply = line.totalSupply;
            const coinPage = "/currencies/";
            const cryptoIndex = index + coinNumber;
            const quoteHistorical = JSON.parse(line.quotesHistorical)
            return (
              cryptos !== undefined && Object.values(cryptos).map((line: any, index: any) => {
                const metaId = line.id;
                if (id === metaId) {
                  const img = line.logo;

                  const TableProps = {
                    id, name, symbol, price, cryptos, percent_change_24h, percent_change_7d, fully_diluted_market_cap, market_cap_dominance,
                    marketCap, volume_24h, quoteHistorical, circulating_supply, max_supply, total_supply, coinPage, cryptoIndex, trending,
                    mostVisited, gainersLosers, img, index, loading, rank, isInWatchList
                  }

                  return (
                    <TableWatchlist key={index} {...TableProps} />
                  );
                }
              })
            );
          })
        }
      </tbody>
    </table>
  )
}

export default BoxTableWatchlist;