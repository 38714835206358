import * as React from 'react';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { userCalls } from '../../api/api';
import FormInput from '../../components/shared/FormInput';
import Button from '../../components/shared/button';
import Error from '../../components/auth/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const SignUp = () => {
    let history = useHistory();

    const [values, setValues] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [ErrorPasswordMessage, setErrorPasswordMessage] = useState('');
    const [ErrorEmailMessage, setErrorEmailMessage] = useState('');
    const [error, setError] = useState<any>();
    const [finished, setFinished] = useState<boolean>();
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    // const handleValidation = () => {
    //     const { password, confirmPassword } = values;

    //     var letter = /[a-z]/;
    //     var upper = /[A-Z]/;
    //     var number = /[0-9]/;

    //     let passwordError = '';

    //     if (password !== confirmPassword) {
    //         passwordError = "Please make sure passwords match.";
    //     }
    //     if (password.length < 7) {
    //         passwordError = 'Password has to be minimum 8 charachters long';
    //     }
    //     if (!letter.test(password)) {
    //         passwordError = "Please make sure password includes a lowercase letter.";
    //     }
    //     if (!upper.test(password)) {
    //         passwordError = 'Password needs to have at least one Uppercase Letter';
    //     }
    //     if (!number.test(password)) {
    //         passwordError = "Please make sure Password Includes a Digit";
    //     }
    //     if (password === '') {
    //         passwordError = "A password is required.";
    //     } 
    //     setErrorPasswordMessage(passwordError);
    // }

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (values.password == values.confirmPassword) {
            if (/\S+@\S+\.\S+/.test(values.email)) {
                userCalls.createUser(values)
                    .then((data) => {
                        setValues({ ...values, email: '', password: '', confirmPassword: '' });
                        console.log(data, 'data')
                        if (data) {
                            setFinished(true);
                        }
                    })
                    .catch((err) => {
                        console.log(err.response, 'errr')
                        // if (err.response.status === 409) {
                        //     setError(err.response.data.message)
                        // } if (err.response.status === 403) {
                        //     setError(err.response.data.message)
                        // }
                    });
            } else {
                setErrorEmailMessage('Please enter a valid Email address')
            }
        } else {
            setErrorPasswordMessage('Passwords dont match')
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="Form">
            <div>
                <div className="title">
                    <h1>Sign Up</h1>
                </div>
                <form className="sign-up-form">
                    <FormInput
                        type="email"
                        value={values.email}
                        label="Email"
                        name="email"
                        onChange={handleChange}
                    />
                    <p>{!finished && ErrorEmailMessage}</p>
                    <FormInput
                        type={passwordShown ? "text" : "password"}
                        value={values.password}
                        label="Password"
                        name="password"
                        onChange={handleChange}
                    />
                    <FontAwesomeIcon onClick={togglePasswordVisiblity} icon={faEye  as IconProp} />
                    <p>{!finished && ErrorPasswordMessage}</p> 
                    <FormInput
                        type={passwordShown ? "text" : "password"}
                        value={values.confirmPassword}
                        label="Confirm Password"
                        name="confirmPassword"
                        onChange={handleChange}
                    />
                    {/* {error && <Error error={error.messages} />} */}
                    {error}
                    {finished && 
                        <div style={{backgroundColor: '#4BCA81'}}>
                            <p>Sign up has been successfull. Please check your email to confirm.</p>
                        </div>
                    }
                </form>

                <div className="footer">
                    <div className="submit">
                        <Button type="submit" handleClick={handleFormSubmit} label="Sign Up" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;