import * as React from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../config.js';
// import News from "../../../assets/Images/news.png";

const HomeNews = (props: any) => {
  const { blogs } = props.props;

  return (
    <div className="news">
      <ul className="news-list">
        {blogs !== undefined && Object.values(blogs).map((line: any, index: any) => {
          const { id, title, description, featured_image, permalink } = line;
          const image = baseUrl + 'blog/' + featured_image;

          return (
            <li key={id} className="item">
              <Link to={{pathname: "/blog/" + permalink}}>
                <div>
                  <img src={image} alt="news" />
                </div>
                <div>
                  <p className="title">{title}</p>
                  <p className="excerpt">{description}</p>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default HomeNews;