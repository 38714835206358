import React, { useEffect } from 'react';
import { blogCalls } from '../../../api/api';
import { useState } from 'react';
import FormInput from '../../../components/shared/FormInput';
import Button from '../../../components/shared/button';
import Parser from 'html-react-parser';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import FormTextarea from '../../../components/shared/FormTextarea';
import { useLocation } from "react-router";

const AddPost = () => {
  
  const location: any = useLocation();
  const [values, setValues] = useState({ title: location.state != undefined ? location.state.title : '', description: location.state != undefined ? location.state.description : '', categoryId: location.state != undefined ? location.state.categoryId : '', content: location.state != undefined ? location.state.content : '', image: location.state != undefined ? location.state.featured_image : '' });
  const [content, setContent] = useState(location.state ? location.state.content : '');
  const [picture, setPicture] = useState<any>(location.state ? location.state.selectedFile : '');
  const [picturePreview, setPicturePreview] = useState<any>({ image: location.state ? location.state.featured_image : '' });
  const [errorMsg, setErrorMsg] = useState('');
  const [edit, setEdit] = useState(location.state ? true : false)

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (values.title && values.description && values.categoryId) {
      if (picture) {
        if (content) {
          const data: any = new FormData()
          data.append('selectedFile', picture)
          data.append("title", values.title);
          data.append("description", values.description);
          data.append("categoryId", values.categoryId);
          data.append("content", content);
          // console.log([...data])
          if (edit === false) {
            console.log(data, 'data')
            blogCalls.addBlogPost(data).then((res: any) => {
              setValues({ ...values, title: '', description: '', categoryId: '' });
              setContent('')
              setErrorMsg('');
              setPicturePreview({ image: '' })
            })
              .catch((err: any) => {
                // if (err.response.status === 400) {
                //   setErrorMsg(err.response.data.message)
                // } if (err.response.status === 500) {
                //   setErrorMsg(err.response.data.message)
                // } if (err.response.status === 404) {
                //   setErrorMsg(err.response.data.message)
                // }
                console.log(err);
              });
          }

        } else {
          alert('No content')
        }
      } else {
        alert('No image attached')
      }
    } else {
      setErrorMsg('Please fill the empty fields')
    }
  };

  const editPost = (e: React.FormEvent) => {
    e.preventDefault();
    if (edit) {
      const data: any = new FormData()
      data.append('selectedFile', picture)
      data.append("title", values.title);
      data.append("description", values.description);
      data.append("categoryId", values.categoryId);
      data.append("content", content);
      data.append("id", location.state.postId)
      console.log([...data])
      if (data) {
        console.log(data, 'data')
        blogCalls.editBlogPost(data).then((res: any) => {
          // setValues({ ...values, title: '', description: '', categoryId: '' });
          // setContent('')
          // setErrorMsg('');
          // setPicturePreview({ image: '' })
          // setContent('')
        })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  }


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageUpload = (targetImgElement: any, index: any, state: any, imageInfo: any, remainingFilesCount: any) => {
    console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
  }

  // const handleEditorChange = (content: any) => {
  //   console.log(content, 'editor content');
  //   setContent(content);
  // };

  const uploadPicture = (e: { target: HTMLInputElement }) => {
    const file: File = (e.target.files as FileList)[0];
    if (e.target.files != null) {
      setPicture(e.target.files[0]);
      console.log(e.target.files[0], 'e.target.files[0]')
      setPicturePreview({
        image: URL.createObjectURL(file)
      });
    }
  };

  console.log(picture, 'picture')

  return (
    <div className='add-post'>
      <div className="main-container">
        <div className='add-post-form'>
          <div className="title">
            <h1>Add a Blog Post</h1>
          </div>
          <form className="form">
            <FormInput type="text" defaultValue={!edit ? values.title : location.state.title} label="Post Title" name="title" onChange={handleChange} />
            <FormTextarea defaultValue={!edit ? values.description : location.state.description} label="Post Short Description (max: 255 characters)" name="description" maxLength={255} onChange={handleChange} />
            <FormInput type="number" defaultValue={!edit ? values.categoryId : location.state.categoryId} label="Post Category id" name="categoryId" onChange={handleChange} min={0} />
            <div className='form-group'>

              <label htmlFor="input-field">Featured Image<span className="req">*</span></label>
              <input type="file" name="selectedFile" onChange={uploadPicture} />
              <img src={picturePreview.image} style={{ width: '250px' }} />
            </div>
            <div className='form-group'>
              <label htmlFor="input-field">Post Content<span className="req">*</span></label>
              <SunEditor
                setOptions={{
                  "defaultTag": "p",

                  colorList: [
                    '#6614E1', '#000', '#707070'
                  ],
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "list",
                      "align",
                      "horizontalRule",
                      "formatBlock",
                      "fontColor",
                      "hiliteColor",
                      "link",
                      "image",
                      "video",
                      "undo",
                      "redo",
                      "codeView",
                      "showBlocks",
                      "preview",
                    ]
                  ]
                }}
                setDefaultStyle="height: 400px"
                onChange={(c) => setContent(c)}
                onImageUpload={handleImageUpload}
                defaultValue={!edit ? content : location.state.content}
              />
            </div>
            <div className="footer">
              <p>{errorMsg}</p>
              {!edit ? (
                <div className="submit">
                  <Button type="submit" handleClick={handleFormSubmit} label="Add Post" />
                </div>
              ) : (
                <div className="submit">
                  <Button type="edit" handleClick={editPost} label="Edit" />
                </div>
              )}
            </div>
          </form>


        </div>

      </div>

      {/* <div className='test'>
        <div className='first'></div>
        <div className='second'></div>
      </div>
      <div className='test2'>
        <div className='first'></div>
        <div className='second'></div>
      </div> */}
    </div>


  );
}

export default AddPost;