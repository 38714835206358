import * as React from 'react';
import { useState, useEffect } from 'react';
import { userCalls } from '../../api/api';
import { useRouter } from '../../components/custom-hooks/useRouter';
import axios from 'axios';
import { Link } from 'react-router-dom';

const VerifyEmail = () => {
    const router: any = useRouter();

    const [value, setValue] = useState({id: router.query.id, token: router.query.token});
    const [verified, setVerified] = useState(Boolean);
    const [error, setError] = useState<any>();

    useEffect(() => {
        // setValue({id: router.query.id})
        console.log(value.id, 'id', value.token, 'token')
        if (value.id !== '') {
            userCalls.verifyEmail(value).then((res) => {
                console.log(res, 'result')
                setVerified(true);
            })
            .catch((err) => {
                setVerified(false);
                // if (err.response.status === 400) {
                //     setError(err.response.data)
                // } if (err.response.status === 404) {
                //     setError(err.response.data)
                // } if (err.response.status === 500) {
                //     setError(err.response.data)
                // }
            });
        }
    }, [value.id])

    return (
        <>
            <div>
                { verified ? (
                    <div>
                        <p>Email successfully verified</p>
                        <Link to="/">Home</Link>
                    </div>
                ) : error}
            </div>
        </>
    )
}

export default VerifyEmail;