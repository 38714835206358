import * as React from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../config.js';
import FAQ from '../Components/FAQ';
// import News from "../../../assets/Images/news.png";

const FrequentlyAskedQuestions = (props: any) => {

  return (
    <div className='faq-general'>
      <FAQ />
    </div>
  )
}

export default FrequentlyAskedQuestions;