import * as React from 'react';
import numberWithCommas from '../../../../helpers/numberCommas';
import convertExponentialToDecimal from '../../../../helpers/decimals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const TrendingCurrencies = (props: any) => {
  const { trending, metadata } = props.props;

  const boxStyle = {
    width: "80%",
    margin: "0 auto",
    background: "rgb(248, 250, 253)",
  };

  const tableStyle = {
    margin: "0 auto",
    borderCollapse: "collapse" as "collapse",
    backgroundColor: "#ffff",
  };

  const w15 = {
    width: "10%",
    textAlign: "left" as "left",
  };

  const w25 = {
    width: "25px",
    paddingRight: "5px",
  };

  const Alignleft = {
    textAlign: "left" as "left",
  };

  const w5 = {
    width: "5%",
  };

  const w10 = {
    width: "10%",
  };

  const rowStyle = {
    height: "40px",
    lineHeight: "1.5em",
    fontSize: "14px",
    borderBottom: "1px solid #eff2f5",
  };

  const cryptoRowStyle = {
    height: "50px",
    borderBottom: "1px solid #eff2f5",
  };

  const fontWeight = {
    fontWeight: "bold" as "bold",
  };

  const numStyle = {
    color: "rgb(88, 102, 126)",
    fontSize: "12px",
  };

  const symbolColor = {
    color: "rgb(128, 138, 157)",
  };
  return (
    <div className="table-wraper-trending-currency">
      <div className="table">
        <table>
          <thead className="table-head">
            <tr className="general-head">
              <th className="table-head-content"></th>
              <th className="table-head-content" scope="col">
                #
              </th>
              <th className="table-head-content">Name</th>
              <th className="table-head-content" scope="col">
                Price
              </th>
              <th className="table-head-content" scope="col">
                24h
              </th>
              <th className="table-head-content" scope="col">
                7d
              </th>
              <th className="table-head-content" scope="col">
                30d
              </th>
              <th className="table-head-content" scope="col">
                Market Cap
              </th>
              <th className="table-head-content" scope="col">
                Volume(24h)
              </th>
              <th className="table-head-different" scope="col">
                Last 7 Days
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {trending !== undefined && trending !== null && Object.values(trending).map((line: any, index: any) => {
              const { id, name, symbol } = line;
              const { price, percent_change_24h, volume_24h, market_cap, percent_change_7d, percent_change_30d } = JSON.parse(line.quote).USD;
              return (
                <tr key={index} className="general-body">
                  <td className="table-body-content">
                    <FontAwesomeIcon icon={faStar as IconProp} />
                  </td>
                  <td className="table-body-content">{index + 1}</td>
                  <td className="table-body-content">
                    {metadata !== undefined &&
                      Object.values(metadata).map((line: any, index: any) => {
                        const metaId = line.id;
                        if (id === metaId) {
                          const img = line.logo;
                          return (
                            <span key={index}>
                              <img style={w25} src={img} alt="logo" />
                            </span>
                          );
                        }
                      })}
                    <span>{name}</span>{" "}
                    <span style={symbolColor}>{symbol}</span>
                  </td>

                  <td className="table-body-content">${convertExponentialToDecimal(price)}</td>

                  <th
                    style={{
                      color: percent_change_24h > 0 ? "#16c784" : "#ea3943", padding: '0px 23px 23px 0px'
                    }}
                  >
                    {percent_change_24h > 0 ? (
                      <FontAwesomeIcon icon={faCaretUp as IconProp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown as IconProp} />
                    )}
                    {Math.abs(percent_change_24h)}%
                  </th>
                  <th
                    style={{
                      color: percent_change_7d > 0 ? "#16c784" : "#ea3943", padding: '0px 23px 23px 0px',
                    }}
                  >
                    {percent_change_7d > 0 ? (
                      <FontAwesomeIcon icon={faCaretUp as IconProp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown as IconProp} />
                    )}
                    {Math.abs(percent_change_7d)}%
                  </th>
                  <th
                    style={{
                      color: percent_change_7d > 0 ? "#16c784" : "#ea3943", padding: '0px 23px 23px 0px',
                    }}
                  >
                    {percent_change_7d > 0 ? (
                      <FontAwesomeIcon icon={faCaretUp as IconProp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown as IconProp} />
                    )}
                    {Math.abs(percent_change_30d)}%
                  </th>
                  <th className="table-body-content">${numberWithCommas(Number(market_cap))}</th>
                  <th className="table-body-content">${numberWithCommas(Number(volume_24h))}</th>
                  <th className="table-body-content">nothing</th>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default TrendingCurrencies;