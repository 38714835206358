import React from 'react';

const SkeletonElement = ({ type }) => {

    const title = {
        width: '100px',
        height: '20px'
    };

    const text = {
        //style here
    };

    const avatar = {
        //style here
    };

    const thumbnail = {
        //style here
        width: '200px',
        height: '200px'
    };

    const skeleton = {
        //style here
        background: '#ddd',
        borderRadius: '4px',
        margin: '10px 0'
    };

    const search = {
        width: '132px',
        height: '32px',
        marginRight: '12px'
    }

    const category = {
        height: '23px',
        width: '50px'
    }

    const coin = {
        height: '500px',
        width: '100%'
    }

    const example = {
        height: '50px',
        width: '100%'
    }

    if (document.getElementById("example")) {
        let test = document.getElementById("example");
        console.log(test.clientWidth, 'width')
    }

    let style;

    switch (type) {
        case "title":
            style = title;
            break;
        case "text":
            style = text;
            break;
        case "avatar":
            style = avatar;
            break;
        case "thumbnail":
            style = thumbnail;
            break;
        case "search":
            style = search;
            break;
        case "category":
            style = category;
            break;
        case "coin":
            style = coin;
            break;
        case "example":
            style = example;
            break;
        default:
            break;
    }

    return (
        <div style={{ ...skeleton, ...style }}></div>
    );
}

export default SkeletonElement;