import React from "react";

const FormTextarea = ({ value, label, name, autoComplete, defaultValue, maxLength, onChange }: { value?: string, defaultValue?: string, label: string, name: string, autoComplete?: string, maxLength?: number, onChange: React.ChangeEventHandler<HTMLElement> }) => (
  <div className="form-group">
    {label && <label htmlFor="input-field">{label}<span className="req">*</span></label>}
    <textarea
      // type={type}
      value={value}
      name={name}
      className="form-control"
      //removed placeholder
      autoComplete={autoComplete}
      maxLength={maxLength}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  </div>
);

export default FormTextarea;