import * as React from 'react';

const RemoveConfirmation = (props: any) => {
    const { removeCoin, setRemove, setRemoveCoin } = props.props;

    return (
        <>
        {removeCoin !== false &&
            <div className='remove-confirmation-wraper'>
                <div className='remove-confirmation-general'>
                    <h2>Remove from Portfolio</h2>
                    <p>Are you sure you want to remove this coin? Any transactions associated with this coin will also be removed.</p>
                    <button className='button' onClick={(() => setRemove(true))}>Remove</button>
                    <button className='button' onClick={(() => setRemoveCoin(false))}>Cancel</button>
                </div>
            </div>
        }
        </>
    )
}

export default RemoveConfirmation;