import React, { useEffect, useState } from "react";
import { AreaChart, Area, YAxis } from "recharts";
import { CryptoCalls } from "../../../api/api";

const AreaGraph = ({ quoteHistorical }: any) => {

  let quoteData: { price: any; }[] = [];

  for (let i = 0; i < 42; i++) {
    if(quoteHistorical !== null) {
      let data = { price: quoteHistorical[i].quote.USD.price }
      quoteData.push(data)
    } else {
      let data = { price: 0 }
      quoteData.push(data)
    }
  }

  return (
    <AreaChart
      width={200}
      height={60}
      data={quoteData}
      style={{ cursor: "pointer" }}
      margin={{
        top: 5,
        right: 0,
        left: 0,
        bottom: 5,
      }}
    >
      <YAxis domain={['auto', 'auto']}
        hide={true} />
      <Area
        type="monotoneX"
        dataKey="price"
        stroke="#16c784"
        fill="rgba(255, 255, 255, .4)"
        strokeWidth={3}
      />
    </AreaChart>
  );
};

export default AreaGraph;
function obj(obj: any): any {
  throw new Error("Function not implemented.");
}

