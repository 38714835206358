import * as React from 'react';
// import CoinAds from './CoinAds';
import { RangeSlider } from '../slider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faStar as filledStar } from "@fortawesome/free-solid-svg-icons";
import { faStar } from '@fortawesome/free-regular-svg-icons';
import convertExponentialToDecimal from '../../../helpers/decimals';
import { useState, useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const CoinPriceInfo = (CoinPriceProps: any) => {
  const { symbol, name, id, rank, img, price, category, percent_change_24h, stats, sliderRecord, setSliderRecords, handleWatchList, isInWatchList,
    clicked, setClicked } = CoinPriceProps;

  useEffect(() => {
    if (isInWatchList && isInWatchList.includes(id)) {
      setClicked(true);
    }
  }, [isInWatchList]);

  return (
    <div className="price-info">
      {/* <div className="first">
        <div>
          <span>Rank #{rank}</span>
          <span className="category">{category}</span>
          <span>On 1,796,056 watchlists</span>
        </div>
        <CoinAds />
      </div> */}

      <div className="second">
        <div>
          <div className='image-holder'>
            <img src={img} alt="logo" />
          </div>
          <div className='coin-details'>
            <p>{name}</p>
            <p>{symbol}</p>
            {clicked ?
              <button className="favorite-fill" onClick={handleWatchList}>
                <FontAwesomeIcon icon={filledStar as IconProp} />
              </button>
              :
              <button className="favorite" onClick={handleWatchList}>
                <FontAwesomeIcon icon={faStar as IconProp} />
              </button>
            }
          </div>
        </div>
        <div>
          <p>${convertExponentialToDecimal(price)}</p>
          <p style={{
            background: percent_change_24h > 0 ? "#16c784" : "#ea3943",
          }}>
            {percent_change_24h > 0 ? (
              <FontAwesomeIcon icon={faCaretUp as IconProp} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown as IconProp} />
            )}
            {Math.abs(percent_change_24h.toFixed(2))}%
          </p>
        </div>
      </div>

      <div className="details">
        <p>{name} Price ({symbol})</p>
        {Object.values(stats).map((line: any, index: any) => {
          const statId = line.id;
          if (id === statId) {
            const { low: low_24h, high: high_24h } =
              line.periods["24h"].quote.USD;
            const { low: low_30d, high: high_30d } =
              line.periods["30d"].quote.USD;
            const { low: low_365d, high: high_365d } =
              line.periods["365d"].quote.USD;
            return (
              <div key={statId} className="range">

                <RangeSlider
                  props={{
                    sliderRecord,
                    low_24h,
                    low_30d,
                    low_365d,
                    price,
                    high_24h,
                    high_30d,
                    high_365d,
                  }}
                />
                <div className="price-stats">
                  <div><p>Low:
                    {sliderRecord == 1 && (
                      <span>${convertExponentialToDecimal(low_24h)}</span>
                    )}
                    {sliderRecord == 2 && (
                      <span>${convertExponentialToDecimal(low_30d)}</span>
                    )}
                    {sliderRecord == 3 && (
                      <span>${convertExponentialToDecimal(low_365d)}</span>
                    )}
                  </p>
                    <p>High:
                      {sliderRecord == 1 && (
                        <span>${convertExponentialToDecimal(high_24h)}</span>
                      )}
                      {sliderRecord == 2 && (
                        <span>${convertExponentialToDecimal(high_30d)}</span>
                      )}
                      {sliderRecord == 3 && (
                        <span>${convertExponentialToDecimal(high_365d)}</span>
                      )}
                    </p></div>
                  <form onChange={setSliderRecords}>
                    <label>
                      <select>
                        <option value={1}>24h</option>
                        <option value={2}>1m</option>
                        <option value={3}>1y</option>
                      </select>
                    </label>
                  </form></div>
              </div>
            );
          }
        })}
      </div>
    </div>
  )
}

export default CoinPriceInfo;