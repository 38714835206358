import * as React from 'react';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { userCalls } from '../../api/api';
import FormInput from '../../components/shared/FormInput';
import Button from '../../components/shared/button';
// @ts-ignore 
import Cookies from 'js-cookie';

const Login = () => {
    let history = useHistory();

    const [values, setValues] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState<string>();

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const isLoggedIn = Cookies.get('cookie');
        if (isLoggedIn) {
            history.push('/');
        } else {
            userCalls.logUser(values)
                .then((res) => {
                    setValues({ ...values, email: '', password: '' });
                    Cookies.set('cookie', res.data.token);
                    Cookies.set('email', res.data.email);
                    if (res.data.admin && res.data.admin !== undefined) {
                        Cookies.set('admin', '1');
                    }
                    history.push('/');
                    history.go(0);
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        setError(err.response.data.message)
                    } if (err.response.status === 404) {
                        setError(err.response.data.message)
                    }
                });
            }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const handleReset = (e: React.FormEvent) => {
        history.push("/forgot-password");
    }

    return (
        <>
            <div className="Form">
                <div>
                    <div className="title">
                        <h1>Log in</h1>
                    </div>

                    <form className="sign-up-form">
                        <FormInput
                            type="email"
                            value={values.email}
                            label="Email"
                            name="email"
                            onChange={handleChange}
                            autoComplete="current-email"
                        />
                        <FormInput
                            type="password"
                            value={values.password}
                            label="Password"
                            name="password"
                            onChange={handleChange}
                            autoComplete="current-password"
                        />
                        {error}
                    </form>

                    <div className="footer">
                        <div className="forgot">
                            <Button type="button" handleClick={handleReset} label="Forgot Password?" />
                        </div>
                        <div className="submit">
                            <Button type="submit" handleClick={handleFormSubmit} label="Log in" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;